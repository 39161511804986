import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { Web3Auth } from "@web3auth/web3auth"
import Torus from "@toruslabs/torus-embed";

export const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "Ducktail Driver Club", // Required
      infuraId: 'https://mainnet.infura.io/v3/dd75afc863424341b0f1933dd9641034' // Required unless you provide a JSON RPC url; see `rpc` below
    }
  },walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: "https://mainnet.infura.io/v3/dd75afc863424341b0f1933dd9641034" // required
    }
  },web3auth: {
    package: Web3Auth, // required
    options: {
      infuraId: "https://mainnet.infura.io/v3/dd75afc863424341b0f1933dd9641034" // required
    }
  }/*,torus: {
    package: Torus,
        options: {
            networkParams: {
                host: "https://www.ducktaildriverclub.com", // optional
                chainId: 1337, // optional
                networkId: 1337 // optional
        } // required
    }
  }*/
};
