import { useCart } from "react-use-cart";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import {isMobile} from 'react-device-detect';
import ReactGA from 'react-ga4';
import { createAlchemyWeb3 } from '@alch/alchemy-web3'
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { useTranslation } from 'react-i18next';
import Web3Modal from "web3modal";
import i18next from 'i18next';
import { providerOptions } from "./providerOptions";
import { ethers } from "ethers";

const ABI = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_to",
				"type": "address"
			},
			{
				"internalType": "uint256[]",
				"name": "_tokenIds",
				"type": "uint256[]"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_state",
				"type": "bool"
			}
		],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "_data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseExtension",
				"type": "string"
			}
		],
		"name": "setBaseExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_newmaxMintAmount",
				"type": "uint256"
			}
		],
		"name": "setmaxMintAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseExtension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "cost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxMintAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maxSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_owner",
				"type": "address"
			}
		],
		"name": "walletOfOwner",
		"outputs": [
			{
				"internalType": "uint256[]",
				"name": "",
				"type": "uint256[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

//PROD 
const ADDRESS = "0xFF44469C29ccF3be8c6051e7E1f75252148aBF45";
//TEST
//const ADDRESS = "0x3a89A7b48d62a81dAF41909f80a307A834e4D5C6";
const Web3Alc = createAlchemyWeb3("https://eth-mainnet.alchemyapi.io/v2/IXfdaN5HNzoMmekghHnJuRbC3WI2dBg-");


function NFTCart() {

    const {
      items,
      removeItem,
      emptyCart,
      totalUniqueItems
    } = useCart();

	const { t } = useTranslation();
	const contractGas = 275000;
	const mintPrice = 0.1;
	const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [newsletter, setNewsletter] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [nftRemoved, setNftRemoved] = useState(0);
    const [contract, setContract] = useState('');
    const [errorMintage, setErrorMintage] = useState(0);
    const [tokenIds, setTokenIds] = useState([]);
    const [mintAmount, setMintAmount] = useState(0);
    const [memberId, setMemberId] = useState(0);
    const [referentialCode, setReferentialCode] = useState('');
    const [isReferentialCodeExisting, setIsReferentialCodeExisting] = useState(0);
    const [memberIdReferential, setMemberIdReferential] = useState(0);
	const [etherPrice, setEtherPrice] = useState(0);
	const [welcomePackage, setWelcomePackage] = useState(false);
    const navigate = useNavigate();

	const web3Modal = new Web3Modal({
		cacheProvider: false, // optional
		providerOptions // required
	  });

	useEffect(() => {
		setGA();
		var tokenIds = [];
		var mintAmount = 0;
		items.forEach(item => {
			tokenIds.push(item.id);
			mintAmount += 0.1;
		});
		setMintAmount(mintAmount);
		setTokenIds(tokenIds);
		getEtherPrice();
		window.scrollTo(0, 0);
	}, []);

	const setGA = () => {
		ReactGA.send({ hitType: "pageview", page: "/cart" });
	};

	const getEtherPrice = async () => {
		const response = await axios.get(`https://api.etherscan.io/api?module=stats&action=ethprice&apikey=3ZJ1E6NZAZ6IVR1BUZI5MST8FRP21FXNXQ`);
		if(response.data.result)
			setEtherPrice(round((response.data.result.ethusd/10), 2));
	}

    const round = (number, decimalPlaces) => {
      const factorOfTen = Math.pow(10, decimalPlaces);
      return Math.round(number * factorOfTen) / factorOfTen;
    }

    const connectWallet = async(e) =>
    {
      	e.preventDefault();
	  	items.forEach(item => {
			verifyNFTStillAvailable(item.id);
		});

		try
		{
			if(window.ethereum)
			{
				const provider = await web3Modal.connect();
				const library = new ethers.providers.Web3Provider(provider);
				const accounts = await library.listAccounts();
				setWalletAddress(accounts[0]);
				setContract(new Web3Alc.eth.Contract(ABI, ADDRESS));
			}
		}
		catch(ex){
			console.log(ex);
		}
    }

	const disconnect = async(e) =>
	{
		e.preventDefault();
		setWalletAddress('');
		setContract(null);
	}

	const mint = async(e) =>
	{
		e.preventDefault();
		items.forEach(item => {
			verifyNFTStillAvailable(item.id);
		});

		var tokenIds = [];
		items.forEach(item => {
			tokenIds.push(item.id);
		});

		setTokenIds(tokenIds);
		var mintRate = Number(await contract.methods.cost().call());
		var totalAmount = mintRate * items.length;
		var gasLimit = contractGas * items.length;

		await contract.methods.mint(walletAddress, tokenIds)
			.send({from: walletAddress,
							to:ADDRESS,
							value: String(totalAmount),
							gas: gasLimit
						}, function(error, transactionHash){
								if(error){
									setErrorMintage(1);
								} else {
									saveFinalTransaction(transactionHash);
								}
							});
	}

	const verifyNFTStillAvailable = async (id) => {
		const response = await axios.get(`https://ducktaildriverclub.com/ddc-api/nfts/${id}`);
		//const response = await axios.get(`http://localhost:7777/api/nfts/${id}`);
		if(response.data.sold === 1){
			removeItem(response.data.id);
			setNftRemoved(nftRemoved+1);
		}
	}

    const saveFinalTransaction = async(mintingTx) =>
    {
		//Save customer if email address filled.
		if(email.length > 0 && email.includes('@'))
			saveCustomerInfo();

		if(referentialCode != '')
			setIsReferentialCodeExist();

		if(mintingTx != "crossminting"){
			//Add Member (active)
			addMember(1);
			//Add Points for Referential Code if exists
			if(isReferentialCodeExisting === 1)
				addPoints(0,0,1,0);
			//Send Welcome Email Notification
			sendWelcomeNotification();
		} else {
			//Add Member (inactive)
			addMember(0);
			//Add Points for Referential Code if exists
			if(isReferentialCodeExisting === 1)
				addPoints(0,0,1,1);
		}

		//update nft
		items.forEach(item => {
		if(mintingTx != "crossminting")
		{
			updateConfirmedMinting(item,mintingTx);
			addPoints(item.id, 1, 0, 0);

			//Send Autopilot notification: Minted successfully
			sendAutoPilotNotificationMintedSuccessfully(item);

			//Redirect
			navigate("/");
		} 
		else
		{
			updatePotentialMinting(item);
			addPoints(item.id, 1, 0, 1);

			//Send Autopilot notification: Potentially minted
			sendAutoPilotNotificationMintedPotentially(item);
			
			//Redirect
			navigate("/confirmation");
		}
	  });
    }

    const saveCustomerInfo = async () => 
    {
		await axios.post('https://ducktaildriverclub.com/ddc-api/nfts/customer/add',
		{
			firstName: firstName,
			lastName: lastName,
			street: street,
			city: city,
			country: country,
			email: email,
			newsletter:newsletter === 'on' ? true : false,
			walletAddress: walletAddress
		});
    }

	const addMember = async (active) => 
    {
		var generatedRefCode = walletAddress.substring(2,2+1) + walletAddress.substring(4,4+1) + walletAddress.substring(6,6+1) + walletAddress.substring(8,8+1)+ walletAddress.substring(10,10+1) + walletAddress.substring(12,12+1) + walletAddress.substring(14,14+1) + walletAddress.substring(16,16+1) + walletAddress.substring(18,18+1) + walletAddress.substring(20,20+1);
		await axios.post('https://ducktaildriverclub.com/ddc-api/members/addMember',
		{
			walletAddress: walletAddress,
			nickname: "Ducktail Driver",
			referentialCode: generatedRefCode,
			referentialCodeUsed: referentialCode,
			emailAddress: email,
			active: active
		});

		//Get Member Id just created.
		const response = await axios.get(`https://ducktaildriverclub.com/ddc-api/members/get/${walletAddress}`);
		if(response.data.id > 0){
			setMemberId(response.data.id);
		}
    }

	const sendAutoPilotNotificationMintedSuccessfully = async (item) => 
    {
		const mailingList = 'yann@ducktaildriverclub.com, quentin@ducktaildriverclub.com';
		//const mailingList = 'yann@ducktaildriverclub.com';

		let axiosConfig = {
			headers: {
				'Content-Type': 'application/json;charset=UTF-8',
				"Access-Control-Allow-Origin": "*",
			}
		  };
		
		//AutoPilot
		const obj = {
		  email: mailingList,
		  subject: "New NFT has been minted!",
		  nftId: item.id,
		  nftName: item.name
		};
		
		await axios.post('https://ducktaildriverclub.com/ddc-api/mailing/newNFTMinted', {
															email: obj.email,
															subject: obj.subject,
															nftId: obj.nftId,
															nftName: obj.nftName,
															}, axiosConfig);
    }

	const sendAutoPilotNotificationMintedPotentially = async (item) => 
    {
		const mailingList = 'yann@ducktaildriverclub.com, quentin@ducktaildriverclub.com';
		//const mailingList = 'yann@ducktaildriverclub.com';

		let axiosConfig = {
			headers: {
				'Content-Type': 'application/json;charset=UTF-8',
				"Access-Control-Allow-Origin": "*",
			}
		  };
		
		//AutoPilot
		const obj = {
		  email: mailingList,
		  subject: "New NFT POTENTIALLY minted!",
		  nftId: item.id,
		  nftName: item.name
		};
		
		await axios.post('https://ducktaildriverclub.com/ddc-api/mailing/NFTPotentiallyMinted', {
															email: obj.email,
															subject: obj.subject,
															nftId: obj.nftId,
															nftName: obj.nftName,
															}, axiosConfig);
    }

	const sendWelcomeNotification = async () => 
    {
		//const mailingList = 'yann@ducktaildriverclub.com';
		var language = i18next.language;
		let axiosConfig = {
			headers: {
				'Content-Type': 'application/json;charset=UTF-8',
				"Access-Control-Allow-Origin": "*",
			}
		  };

		if(email.length > 0)
		{
			//Welcome email
			const mailingList = email+', info@ducktaildriverclub.com';
			const obj = {
				email: mailingList,
				subject: language === 'fr' ? "Bienvenue dans le Ducktail Driver Club!" : "Welcome to the Ducktail Driver Club!"
			};
			await axios.post('https://ducktaildriverclub.com/ddc-api/mailing/welcomeNotification', {
																email: obj.email,
																subject: obj.subject,
																lang: language
																}, axiosConfig);
		}
    }

	const updateConfirmedMinting = async (item, mintingTx) => 
    {
		await axios.patch(`https://ducktaildriverclub.com/ddc-api/nfts/potentialMinting/${item.id}`,
		{
			verifyMinting: 0,
			mintingAddress: walletAddress,
			mintingTx: mintingTx,
			sold:1,
			currentOwnerAddress: walletAddress
		});
    }
	
	const updatePotentialMinting = async (item) => 
    {
		await axios.patch(`https://ducktaildriverclub.com/ddc-api/nfts/potentialMinting/${item.id}`,
		{
			verifyMinting: 1,
			mintingAddress: "crossmint",
			mintingTx: "crossmint",
			sold:0,
			currentOwnerAddress: "crossmint"
		});
    }

	const addPoints = async (nftId, isNFT, isReferential, crossmint) => 
    {
		var points = 0;
		var remarks = "";
		var state = "Validated";
		var active = 1;
		if(isNFT === 1)
		{
			points = 100;
			remarks = "MINTING DDC NFT";
			if(crossmint === 1)
			{
				state = "Pending";
				active = 0;
			}		
			await axios.post('https://ducktaildriverclub.com/ddc-api/points/add',
			{
				memberId: memberId,
				nftId: nftId,
				points: points,
				remarks: remarks,
				state: state,
				active: active,
				eventsSubscriptionId:0
			});

			if(welcomePackage)
			{
				points = -100;
				remarks = "ORDERING WELCOME PACKAGE";
				await axios.post('https://ducktaildriverclub.com/ddc-api/points/add',
				{
					memberId: memberId,
					nftId: nftId,
					points: points,
					remarks: remarks,
					state: state,
					active: active,
					eventsSubscriptionId:0
				});
			}
		}
		else if(isReferential === 1){
			points = 20;
			remarks = "Referential Code";			
			//Add points to new member
			await axios.post('https://ducktaildriverclub.com/ddc-api/points/add',
			{
				memberId: memberId,
				nftId: 0,
				points: points,
				remarks: remarks,
				state: state,
				active: active,
				eventsSubscriptionId:0
			});

			//Add points to referential member if exists
			if(memberIdReferential > 0)
			{
				await axios.post('https://ducktaildriverclub.com/ddc-api/points/add',
				{
					memberId: memberIdReferential,
					nftId: 0,
					points: points,
					remarks: remarks,
					state: state,
					active: active,
					eventsSubscriptionId:0
				});
			}
		}
    }

	const setIsReferentialCodeExist = async() =>{
		//Get Member Id just created.
		const response = await axios.get(`https://ducktaildriverclub.com/ddc-api/members/getReferentialCode/${referentialCode}`);
		if(response.data.id > 0){
			setIsReferentialCodeExisting(1);
			setMemberIdReferential(response.data.id);
		}
	}
	
	const showWelcomePackageAddress = async (checked) => {
		setWelcomePackage(checked);
		if(checked)
			document.getElementById("welcome-pck-address").style.display="inline";
		else
			document.getElementById("welcome-pck-address").style.display="none";
	}

    const emptyNFTCart = async() =>
    {
      emptyCart();
	  navigate("/");
    }
    return (
      <>
      <section>
      <div className="container mt-2">
        <div className="row justify-content-between">
          <div className="col-lg-7 mb-5 mb-lg-0">
          <div>
            <span>
              <span className="fs-1 my-4 text-primary text-red">{t('CART_CHECKOUT')}</span>
			  <br/>
			  {isMobile ? <span className='text-secondary fs-6 text-red'><br/>{t('MOBILE_DEVICE_WARNING')}.<br/></span> : ""}
			  <span className="pt-5 px-2">
				{t('CART_INTRO')}
			  </span>
			  <br/>
			  <span className="pt-5 px-2">
				{t('CART_INTRO2')}
			  	<br/>
				<br/>
				<ul className="list-unstyled">
					<li className="px-2">
						<label htmlFor="exampleInput" className="form-label">Email (*)</label>
						<input type="text" className="form-control form-control-sm" id="email" placeholder="Email" onChange={ (e) => setEmail(e.target.value) } required/>
					</li>
				</ul>
			  </span>
			  <br/>
			  <br/>
			  <span className="pt-5"> 
				<span className="fs-2 my-4 text-primary text-black">
					{t('CART_REFERENTIAL')}
				</span>
					<ul className="list-unstyled">
						<li className="px-2">
							{t('CART_REFERENTIAL_DESC')}.
							<br/><br/>
							<input type="text" className="form-control form-control-sm" id="referentialCode" placeholder={t('CART_REFERENTIAL_CODE')} onChange={ (e) => setReferentialCode(e.target.value) }/>
						</li>
					</ul>
				</span>
			  <br/>
			  <div className="mt-2">
				<div className="form-check form-switch">
					<input className="form-check-input" type="checkbox" id="wantsWelcomePackage" placeholder="wantsWelcomePackage" onChange={ (e) => showWelcomePackageAddress(e.target.checked) }></input>
					<label className="form-check-label" htmlFor="wantsWelcomePackage">{t('CART_WALLDECOR_RECEIVE')} <span className="ps-1 text-red">{t('CART_WALLDECOR_RECEIVE2')}</span></label>
				</div>
              <br/>
				{/* Address  */}
				<div className="accordion-collapse collapse" aria-labelledby="heading-1" id='welcome-pck-address'>
					<span className="fs-2 my-4 text-primary text-black">{t('COLLECTION_WALLDECOR_TITLE')}</span>
              		<br/>
					<span className="px-1">
						{t('CART_WALLDECOR_INTRO')} {t('CART_WALLDECOR_INTRO3')}. 
						{t('CART_WALLDECOR_INTRO4')}.
						<br/>
						<span className="px-1 text-red">{t('CART_WALLDECOR_INTRO5')}.</span>
					</span>
					<br/>
					<div className="accordion-body">
						<label htmlFor="exampleInput" className="form-label">{t('CART_WALLDECOR_FIRSTNAME')} (*)</label>
						<input type="text" className="form-control form-control-sm" id="firstName" placeholder={t("CART_WALLDECOR_FIRSTNAME")} onChange={ (e) => setFirstName(e.target.value) } required/>
						<br/>
						<label htmlFor="exampleInput" className="form-label">{t('CART_WALLDECOR_LASTNAME')} (*)</label>
						<input type="text" className="form-control form-control-sm" id="lastName" placeholder={t('CART_WALLDECOR_LASTNAME')} onChange={ (e) => setLastName(e.target.value) } required/>
						<br/>
						<label htmlFor="exampleInput" className="form-label">{t('CART_WALLDECOR_STREET')} (*)</label>
						<input type="text" className="form-control form-control-sm" id="street" placeholder={t('CART_WALLDECOR_STREET')} onChange={ (e) => setStreet(e.target.value) } required/>
						<br/>
						<label htmlFor="exampleInput" className="form-label">{t('CART_WALLDECOR_NPACITY')} (*)</label>
						<input type="text" className="form-control form-control-sm" id="city" placeholder={t('CART_WALLDECOR_NPACITY')} onChange={ (e) => setCity(e.target.value) } required/>
						<br/>
						<label htmlFor="exampleInput" className="form-label"></label>
						<select className="form-select" aria-label="Country (*) " placeholder="country" onChange={ (e) => setCountry(e.target.value) } required>
						<option value="" selected="selected">{t('CART_WALLDECOR_COUNTRY')}</option> 
							<option value="Afghanistan">Afghanistan</option> 
							<option value="Albania">Albania</option> 
							<option value="Algeria">Algeria</option> 
							<option value="American Samoa">American Samoa</option> 
							<option value="Andorra">Andorra</option> 
							<option value="Angola">Angola</option> 
							<option value="Anguilla">Anguilla</option> 
							<option value="Antarctica">Antarctica</option> 
							<option value="Antigua and Barbuda">Antigua and Barbuda</option> 
							<option value="Argentina">Argentina</option> 
							<option value="Armenia">Armenia</option> 
							<option value="Aruba">Aruba</option> 
							<option value="Australia">Australia</option> 
							<option value="Austria">Austria</option> 
							<option value="Azerbaijan">Azerbaijan</option> 
							<option value="Bahamas">Bahamas</option> 
							<option value="Bahrain">Bahrain</option> 
							<option value="Bangladesh">Bangladesh</option> 
							<option value="Barbados">Barbados</option> 
							<option value="Belarus">Belarus</option> 
							<option value="Belgium">Belgium</option> 
							<option value="Belize">Belize</option> 
							<option value="Benin">Benin</option> 
							<option value="Bermuda">Bermuda</option> 
							<option value="Bhutan">Bhutan</option> 
							<option value="Bolivia">Bolivia</option> 
							<option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option> 
							<option value="Botswana">Botswana</option> 
							<option value="Bouvet Island">Bouvet Island</option> 
							<option value="Brazil">Brazil</option> 
							<option value="British Indian Ocean Territory">British Indian Ocean Territory</option> 
							<option value="Brunei Darussalam">Brunei Darussalam</option> 
							<option value="Bulgaria">Bulgaria</option> 
							<option value="Burkina Faso">Burkina Faso</option> 
							<option value="Burundi">Burundi</option> 
							<option value="Cambodia">Cambodia</option> 
							<option value="Cameroon">Cameroon</option> 
							<option value="Canada">Canada</option> 
							<option value="Cape Verde">Cape Verde</option> 
							<option value="Cayman Islands">Cayman Islands</option> 
							<option value="Central African Republic">Central African Republic</option> 
							<option value="Chad">Chad</option> 
							<option value="Chile">Chile</option> 
							<option value="China">China</option> 
							<option value="Christmas Island">Christmas Island</option> 
							<option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option> 
							<option value="Colombia">Colombia</option> 
							<option value="Comoros">Comoros</option> 
							<option value="Congo">Congo</option> 
							<option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option> 
							<option value="Cook Islands">Cook Islands</option> 
							<option value="Costa Rica">Costa Rica</option> 
							<option value="Cote D'ivoire">Cote D'ivoire</option> 
							<option value="Croatia">Croatia</option> 
							<option value="Cuba">Cuba</option> 
							<option value="Cyprus">Cyprus</option> 
							<option value="Czech Republic">Czech Republic</option> 
							<option value="Denmark">Denmark</option> 
							<option value="Djibouti">Djibouti</option> 
							<option value="Dominica">Dominica</option> 
							<option value="Dominican Republic">Dominican Republic</option> 
							<option value="Ecuador">Ecuador</option> 
							<option value="Egypt">Egypt</option> 
							<option value="El Salvador">El Salvador</option> 
							<option value="Equatorial Guinea">Equatorial Guinea</option> 
							<option value="Eritrea">Eritrea</option> 
							<option value="Estonia">Estonia</option> 
							<option value="Ethiopia">Ethiopia</option> 
							<option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option> 
							<option value="Faroe Islands">Faroe Islands</option> 
							<option value="Fiji">Fiji</option> 
							<option value="Finland">Finland</option> 
							<option value="France">France</option> 
							<option value="French Guiana">French Guiana</option> 
							<option value="French Polynesia">French Polynesia</option> 
							<option value="French Southern Territories">French Southern Territories</option> 
							<option value="Gabon">Gabon</option> 
							<option value="Gambia">Gambia</option> 
							<option value="Georgia">Georgia</option> 
							<option value="Germany">Germany</option> 
							<option value="Ghana">Ghana</option> 
							<option value="Gibraltar">Gibraltar</option> 
							<option value="Greece">Greece</option> 
							<option value="Greenland">Greenland</option> 
							<option value="Grenada">Grenada</option> 
							<option value="Guadeloupe">Guadeloupe</option> 
							<option value="Guam">Guam</option> 
							<option value="Guatemala">Guatemala</option> 
							<option value="Guinea">Guinea</option> 
							<option value="Guinea-bissau">Guinea-bissau</option> 
							<option value="Guyana">Guyana</option> 
							<option value="Haiti">Haiti</option> 
							<option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option> 
							<option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option> 
							<option value="Honduras">Honduras</option> 
							<option value="Hong Kong">Hong Kong</option> 
							<option value="Hungary">Hungary</option> 
							<option value="Iceland">Iceland</option> 
							<option value="India">India</option> 
							<option value="Indonesia">Indonesia</option> 
							<option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option> 
							<option value="Iraq">Iraq</option> 
							<option value="Ireland">Ireland</option> 
							<option value="Israel">Israel</option> 
							<option value="Italy">Italy</option> 
							<option value="Jamaica">Jamaica</option> 
							<option value="Japan">Japan</option> 
							<option value="Jordan">Jordan</option> 
							<option value="Kazakhstan">Kazakhstan</option> 
							<option value="Kenya">Kenya</option> 
							<option value="Kiribati">Kiribati</option> 
							<option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option> 
							<option value="Korea, Republic of">Korea, Republic of</option> 
							<option value="Kuwait">Kuwait</option> 
							<option value="Kyrgyzstan">Kyrgyzstan</option> 
							<option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option> 
							<option value="Latvia">Latvia</option> 
							<option value="Lebanon">Lebanon</option> 
							<option value="Lesotho">Lesotho</option> 
							<option value="Liberia">Liberia</option> 
							<option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option> 
							<option value="Liechtenstein">Liechtenstein</option> 
							<option value="Lithuania">Lithuania</option> 
							<option value="Luxembourg">Luxembourg</option> 
							<option value="Macao">Macao</option> 
							<option value="North Macedonia">North Macedonia</option> 
							<option value="Madagascar">Madagascar</option> 
							<option value="Malawi">Malawi</option> 
							<option value="Malaysia">Malaysia</option> 
							<option value="Maldives">Maldives</option> 
							<option value="Mali">Mali</option> 
							<option value="Malta">Malta</option> 
							<option value="Marshall Islands">Marshall Islands</option> 
							<option value="Martinique">Martinique</option> 
							<option value="Mauritania">Mauritania</option> 
							<option value="Mauritius">Mauritius</option> 
							<option value="Mayotte">Mayotte</option> 
							<option value="Mexico">Mexico</option> 
							<option value="Micronesia, Federated States of">Micronesia, Federated States of</option> 
							<option value="Moldova, Republic of">Moldova, Republic of</option> 
							<option value="Monaco">Monaco</option> 
							<option value="Mongolia">Mongolia</option> 
							<option value="Montserrat">Montserrat</option> 
							<option value="Morocco">Morocco</option> 
							<option value="Mozambique">Mozambique</option> 
							<option value="Myanmar">Myanmar</option> 
							<option value="Namibia">Namibia</option> 
							<option value="Nauru">Nauru</option> 
							<option value="Nepal">Nepal</option> 
							<option value="Netherlands">Netherlands</option> 
							<option value="Netherlands Antilles">Netherlands Antilles</option> 
							<option value="New Caledonia">New Caledonia</option> 
							<option value="New Zealand">New Zealand</option> 
							<option value="Nicaragua">Nicaragua</option> 
							<option value="Niger">Niger</option> 
							<option value="Nigeria">Nigeria</option> 
							<option value="Niue">Niue</option> 
							<option value="Norfolk Island">Norfolk Island</option> 
							<option value="Northern Mariana Islands">Northern Mariana Islands</option> 
							<option value="Norway">Norway</option> 
							<option value="Oman">Oman</option> 
							<option value="Pakistan">Pakistan</option> 
							<option value="Palau">Palau</option> 
							<option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option> 
							<option value="Panama">Panama</option> 
							<option value="Papua New Guinea">Papua New Guinea</option> 
							<option value="Paraguay">Paraguay</option> 
							<option value="Peru">Peru</option> 
							<option value="Philippines">Philippines</option> 
							<option value="Pitcairn">Pitcairn</option> 
							<option value="Poland">Poland</option> 
							<option value="Portugal">Portugal</option> 
							<option value="Puerto Rico">Puerto Rico</option> 
							<option value="Qatar">Qatar</option> 
							<option value="Reunion">Reunion</option> 
							<option value="Romania">Romania</option> 
							<option value="Russian Federation">Russian Federation</option> 
							<option value="Rwanda">Rwanda</option> 
							<option value="Saint Helena">Saint Helena</option> 
							<option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option> 
							<option value="Saint Lucia">Saint Lucia</option> 
							<option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option> 
							<option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option> 
							<option value="Samoa">Samoa</option> 
							<option value="San Marino">San Marino</option> 
							<option value="Sao Tome and Principe">Sao Tome and Principe</option> 
							<option value="Saudi Arabia">Saudi Arabia</option> 
							<option value="Senegal">Senegal</option> 
							<option value="Serbia and Montenegro">Serbia and Montenegro</option> 
							<option value="Seychelles">Seychelles</option> 
							<option value="Sierra Leone">Sierra Leone</option> 
							<option value="Singapore">Singapore</option> 
							<option value="Slovakia">Slovakia</option> 
							<option value="Slovenia">Slovenia</option> 
							<option value="Solomon Islands">Solomon Islands</option> 
							<option value="Somalia">Somalia</option> 
							<option value="South Africa">South Africa</option> 
							<option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option> 
							<option value="Spain">Spain</option> 
							<option value="Sri Lanka">Sri Lanka</option> 
							<option value="Sudan">Sudan</option> 
							<option value="Suriname">Suriname</option> 
							<option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option> 
							<option value="Swaziland">Swaziland</option> 
							<option value="Sweden">Sweden</option> 
							<option value="Switzerland">Switzerland</option> 
							<option value="Syrian Arab Republic">Syrian Arab Republic</option> 
							<option value="Taiwan, Province of China">Taiwan, Province of China</option> 
							<option value="Tajikistan">Tajikistan</option> 
							<option value="Tanzania, United Republic of">Tanzania, United Republic of</option> 
							<option value="Thailand">Thailand</option> 
							<option value="Timor-leste">Timor-leste</option> 
							<option value="Togo">Togo</option> 
							<option value="Tokelau">Tokelau</option> 
							<option value="Tonga">Tonga</option> 
							<option value="Trinidad and Tobago">Trinidad and Tobago</option> 
							<option value="Tunisia">Tunisia</option> 
							<option value="Turkey">Turkey</option> 
							<option value="Turkmenistan">Turkmenistan</option> 
							<option value="Turks and Caicos Islands">Turks and Caicos Islands</option> 
							<option value="Tuvalu">Tuvalu</option> 
							<option value="Uganda">Uganda</option> 
							<option value="Ukraine">Ukraine</option> 
							<option value="United Arab Emirates">United Arab Emirates</option> 
							<option value="United Kingdom">United Kingdom</option> 
							<option value="United States">United States</option> 
							<option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option> 
							<option value="Uruguay">Uruguay</option> 
							<option value="Uzbekistan">Uzbekistan</option> 
							<option value="Vanuatu">Vanuatu</option> 
							<option value="Venezuela">Venezuela</option> 
							<option value="Viet Nam">Viet Nam</option> 
							<option value="Virgin Islands, British">Virgin Islands, British</option> 
							<option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option> 
							<option value="Wallis and Futuna">Wallis and Futuna</option> 
							<option value="Western Sahara">Western Sahara</option> 
							<option value="Yemen">Yemen</option> 
							<option value="Zambia">Zambia</option> 
							<option value="Zimbabwe">Zimbabwe</option>
						</select>
					</div>
					<span>
						<i>{t('CART_WALLDECOR_KINDLY')}.</i>
					</span>
             		<br/>
              <br/>
				</div>
			</div>
			<div className="form-check form-switch">
				<input className="form-check-input" type="checkbox" id="wantsNewsletter" placeholder="wantsNewsletter" onChange={ (e) => setNewsletter(e.target.value) }></input>
				<label className="form-check-label" htmlFor="wantsNewsletter">{t('CART_WALLDECOR_SUBSCRIBENEWSLETTER')}</label>
			</div>
			</span>
			</div>
			<br/>
            <div>
                <a href="/">
                  <i className="bi bi-arrow-left"></i> 
                  <span className="p-1">{t('CART_BACKTOSHOP')}</span>
                </a>
            </div>
          </div>
          <div className="col-lg-5 ps-md-5 pt-md-5">
            <div className="bg-light">
              <div className="accordion accordion-classic" id="accordion-1">
              <div className="accordion-item">
                  <span className="accordion-header" id="heading-1-1">
                    <button className="accordion-button collapsed fw-bold fs-2" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapse-1-1" aria-expanded="true" aria-controls="collapse-1-1">
                      {totalUniqueItems} Nft(s)
                    </button>
                  </span>
                  <div id="collapse-1-1" className="accordion-collapse" aria-labelledby="heading-1-1"
                    data-bs-parent="#accordion-1">
                    <div className="accordion-body">
                      <ul className="list-unstyled">
                        {items.map((item) => (
                            <li key={item.id}>
                            <div className="row g-2 g-lg-3 align-items-center">
                              <a href={"/nft=" + item.id} target="_blank" className="col-3 col-md-3">
                                <img className="img-fluid" src={"./assets/images/NFT/real/JPEG/"+item.id+".jpg"} alt="Product"/>
                              </a>
                              <div className="col">
                                <a href={"/nft=" + item.id} target="_blank" className="text-black text-hover-primary lead">{item.name}</a>
                                <ul className="list-inline text-muted">
                                  <li className="list-inline-item">{t('CART_PRICE')}: <span className="text-secondary"><svg stroke-width="0" viewBox="0 0 320 512" className="text-grey text-14" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z"></path></svg> 0.1</span></li>
                                </ul>
                                <a onClick={() => removeItem(item.id)} className="text-red underline">{t('CART_REMOVE')}</a>
                              </div>
                            </div>
                          </li>
                        ))}
                        </ul>
						{items.length > 0 ? 
						<span>
							<ul className="list-unstyled">
								<li className="mt-1">
									<a onClick={() => emptyNFTCart()}>									
										<span className="p-1 mt-3 text-red"><i className="bi bi-cart-x mx-1"></i>{t('CART_EMPTY')}</span>
									</a>
								</li>
							</ul>
						</span>: <ul></ul>}
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
					<center>
						<div className="accordion-header" id="heading-1-3">
							<span className="text-primary text-black ps-3 fs-2">Total</span> 
							<span className="text-black fs-3 ps-3">{round(mintPrice * items.length,3)} ETH</span>
							<br/>
							<span className="text-muted ps-1 p-2">(approx. {etherPrice* items.length} $)</span>
						</div>
					</center>
                </div>
                <div className="accordion-item">
                  <div className="d-grid text-center">
                    <br/>
						{items.length > 0 ? walletAddress === '' ? 
							<div>
							<a href="" onClick={(e) => connectWallet(e)}>
								<button className="btn btn-lg btn-primary rounded-pill">
								<i className="bi bi-layers-half"></i> 
								<span> {t('CONNECT_WALLET')} </span>
								</button>
							</a>
								<center>
								<br/>
								<CrossmintPayButton	
									environment="production"
									collectionPhoto="https://ducktaildriverclub.com/assets/images/NFT/2.jpg"
									collectionTitle="Ducktail Driver Club"
									collectionDescription="Join the best petrolhead NFT community and earn incredible rewards."
									clientId="5c3b051d-c6b0-49c8-ad2a-9a7c2ee9885b"
									mintConfig={{"type":"erc-721","totalPrice":mintAmount.toString(),"_tokenIds":tokenIds}}
									onClick={() => saveFinalTransaction("crossminting")}
								/>
								</center>
							</div>
							:
							<div>
								{nftRemoved > 0 ? <span className="text-red">{nftRemoved} NFT {nftRemoved > 1 ? "s" : ""} in your cart have been sold already and {nftRemoved > 1 ? "have" : "has"} been removed from the cart.</span> : ""}
								<span className="fs-6 text-blue">
									<i className="bi bi-check-circle"></i>
									<span className="ps-1">
										<b>{walletAddress === '' ? t('MINT_NOT_CONNECTED') : t('MINT_CONNECTED')}</b>
									</span>
								</span>
								<br/>
								{walletAddress}
								{ errorMintage > 0 ? <div className="p-1"><h6 className="text-red">{t('CART_ERROR_WALLET')} </h6></div> : ''}
								<br/>
								<a href="" onClick={(e) => mint(e)}>
									<button className="btn btn-md btn-primary rounded-pill mt-2 fs-5">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-car-front-fill" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189l.956-1.913A.5.5 0 0 1 4.309 3h7.382a.5.5 0 0 1 .447.276l.956 1.913a.51.51 0 0 1-.497.731c-.91-.073-3.35-.17-4.597-.17-1.247 0-3.688.097-4.597.17a.51.51 0 0 1-.497-.731Z"/>
										</svg>
										<span className='ps-1'><b>{t('MINT')}</b></span>
									</button>
								</a>
								<span>
									<ul className="list-unstyled">
										<li className="mt-2">
											<a href="" onClick={(e) => disconnect(e)}>									
												<span className="p-1 mt-3 text-muted"><i className="bi bi-x-circle mx-1"></i>{t('MINT_DISCONNECT')}</span>
											</a>
										</li>
									</ul>
								</span>
							</div>
								: <span className="text-red"><b>{t('CART_IS_EMPTY')}.</b><br/></span>
								/*:  <span className="text-red">
									<img src="./assets/images/svg/meta.svg" alt="metamask logo" width={50} height={50}/><br/><a href="https://metamask.io/" target="_blank"><b>Please install & use Metamask.</b></a></span>*/}
							
							<div className="mt-2">
								<span>
									{t('CART_CG_ACCEPT')} <a href="/legalTerms" target="_blank" className="text-blue">{t('CART_CG')}.</a>
								</span>
								<br/>
								<span className="text-muted pt-2">
									<i className="bi bi-shield-lock fs-6 me-1"></i>
									{t('SECURE_TRANSACTION')}
								</span>
							</div>
                      <br/>
                  </div>
                </div>
              </div>
            </div>
			<div className="card-footer">
			</div>
          </div>
          </div>
          </div>
          </section> <br/> <br/>
        </>
    );
  }
  export default NFTCart