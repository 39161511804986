import React, { useState, useEffect } from 'react'
import './App.css';
import Shop  from './Layout/Shop';
import NoPage  from './Layout/NoPage';
import NFTDetail from "./Layout/NFTDetail";
import {
  BrowserRouter as Router,  
  Routes,
  Route
} from 'react-router-dom';
import HomeV3 from './Layout/V3/Home';
import HomeV2 from './Layout/V2/Home';
import Available from './Layout/V3/Available';
import Utility from './Layout/V3/Utility';
import PreSelection from './Layout/V2/PickNFT';
import ShopCart from './Layout/ShopCart';
import axios from "axios";
import Confirmation from './Layout/Confirmation';
import ReactGA from "react-ga4";
import OfficialLinks from './Layout/OfficialLinks';
import ClubSignIn from './Layout/ClubSignIn';
import ClubAccount from './Layout/ClubAccount';
import ClubEvents from './Layout/ClubEvents';
import ClubProfile from './Layout/ClubProfile';
import ClubCollection from './Layout/ClubCollection';
import { ReactSession } from 'react-client-session';
import Page404 from './Layout/Page404';
import ClubFAQ from './Layout/ClubFaq';
import LegalTerms from './Layout/LegalTerms';
import Events from './Layout/V2/Event';
import WhitePaper from './Layout/Whitepaper';

function App ()
{
    const [nfts, setNFTs] = useState([]);
		ReactSession.setStoreType("sessionStorage");
    ReactSession.set("lang", "fr");
    
    useEffect(() => {
        getNFTs();
        setGA();
    }, []);

    const getNFTs = async () => {
        const response = await axios.get(`https://ducktaildriverclub.com/ddc-api/nfts`);
        setNFTs(response.data);
    }

    const setGA = () => {
      ReactGA.initialize('G-FH8NGS59Z4');
    };

    return (
      <>
      <Router>
        <Routes>
          <Route index element={<HomeV3 />} />
          <Route path='available' element={< Available />}></Route>
          <Route path='utility' element={< Utility />}></Route>
          {/*<Route path='shop' element={< Shop nfts={ nfts }/>}></Route>
          <Route path='cart' element={< ShopCart />}></Route>
          <Route path='confirmation' element={< Confirmation />}></Route>
          <Route path='official-links' element={< OfficialLinks />}></Route>
          <Route path='nft=:id' element={< NFTDetail />}></Route>
          <Route path='confirmation' element={< NoPage />}></Route>
          <Route path='legalTerms' element={< LegalTerms />} />
          <Route path='events' element={< Events />}></Route>
          <Route path='whitepaper' element={< WhitePaper />}></Route>
          <Route path='preselection' element={< PreSelection />}></Route>
          <Route path='*' element={< Page404 />} />
          <Route path='enter-the-club' element={< ClubSignIn />}></Route>
          <Route path='ddc-profile-dashboard' element={< ClubProfile />}></Route>
          <Route path='ddc-events-dashboard' element={< ClubEvents />}></Route>
          <Route path='ddc-collection-dashboard' element={< ClubCollection />}></Route>
          <Route path='ddc-welcome-dashboard' element={< ClubAccount />}></Route>
          <Route path='ddc-faq-dashboard' element={< ClubFAQ />}></Route>*/}
        </Routes>
      </Router>
      </>
    );
}
  
export default App;