import React from 'react'  
import NFTCartPreview from '../components/NFTCartPreview'
import NFTItem from '../components/NFTItem'
import Header from './Headers/HeaderShop'
import Footer from './Footer/Footer'

function NFTDetail () {
        return (
          <div>
             <Header/>
             <NFTCartPreview/>

            <div className="offcanvas-wrap">
              <NFTItem/>
              <Footer/>
            </div>
          </div>
  )
}
export default NFTDetail