import Footer from '../Footer/V3/Footer';
import Header from '../Headers/V3/Header';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import React,{ useEffect} from 'react';

function Available () {
    
        const nfts = 0;
        const pageTitle = "Ducktail Driver Club | Utility";  

        useEffect(() => {
          setGA();
        }, []);

        const setGA = () => {
            ReactGA.send({ hitType: "pageview", page: "/utility" });
        };        
        <Header></Header>
		
        return (
            <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="offcanvas-wrap">
            
            <div className="row justify-content-end pt-2" data-aos="fade-right">
                <div className="col-12 text-center pt-0">
                    <h5 className="fs-7">
                        <a href="/" title="Go back home" className="action underline text-black fs-6">
                            <span>
                                <i className="bi bi-chevron-double-left display-6 fw-bold text-primary text-black"></i>
                                GO BACK HOME
                            </span>                                       
                        </a> 
                    </h5>
                </div>
            </div>
           <section className="py-5" id="events" data-aos="fade-in-down">
                <div className="container">
                    <div className="row sm-12 md-12">
                        <center>
                            <div className="row d-inline-flex align-items-center mb-0 text-center">
                                <div className="col-12 text-red">
                                    <span className='fs-1 text-primary'><a href="/">Ducktail Driver Club</a></span>
                                    <br/>
                                    <span className='fs-1 text-primary text-black'>Package</span>
                                </div>
                            </div>
                            <div className="row d-inline-flex align-items-center mb-0">
                                <div className="col-lg-2 mb-4 mb-lg-0"></div>
                                <div className="col-lg-4 mb-4 mb-lg-0">
                                    <img
                                    src="./assets/images/NFT/box-walldecor.jpg"
                                    className="w-100 shadow-1-strong rounded mb-4"
                                    alt="Package walldecor"
                                    />
                                </div>
                                <div className="col-lg-4 mb-3 mb-lg-0">
                                    <img
                                    src="./assets/images/NFT/box-goodies.jpg"
                                    className="w-100 shadow-1-strong rounded mb-4"
                                    alt="Package goodies"
                                    />
                                </div>
                                <div className="col-lg-2 mb-4 mb-lg-0"></div>
                            </div>
                        </center>
                        <div className="col-12" data-aos="fade-in-down">
                            <div className="fs-4">
                                <ul className="list-unstyled">
                                    <center>
                                        <li className="pt-2 pt-md-10 text-primary fs-2">
                                            What do you get...
                                        </li>
                                    </center>
                                    <li className="pt-1 text-secondary fs-5">
                                        <i className="bi bi-gift text-red fs-5"></i> 
                                        <span className='ps-2 fs-5'>High-end metal print of the 1/1 Ducktail Driver Club NFT you will get as an Airdrop.</span>
                                    </li>
                                    <li className="pt-1 text-secondary fs-6">
                                        <i className="bi bi-arrow-return-right text-red fs-5 px-5"></i><b>Size:</b> 30 x 30cms (11.8 x 11.8 inches).
                                    </li>
                                    <li className="pt-1 text-secondary fs-6">
                                        <i className="bi bi-arrow-return-right text-red fs-5 px-5"></i><b>Composite:</b> High-Resolution Versatile Metal Print (Aluminium).
                                    </li>
                                    <li className="pt-1 text-secondary fs-6">
                                        <i className="bi bi-arrow-return-right text-red fs-5 px-5"></i><b>Frame:</b> Dark American Box.
                                    </li>
                                    <li className="pt-1 text-secondary fs-5">
                                        <i className="bi bi-gift display-6 fw-bold text-primary text-red"></i>
                                        <span className='ps-2 fs-5'>1x Certificat of Authenticity</span>
                                    </li>
                                    <li className="pt-1 text-secondary fs-5">
                                        <i className="bi bi-gift display-6 fw-bold text-primary text-red"></i>
                                        <span className='ps-2 fs-5'>1x Keychain</span>
                                    </li>
                                    <li className="pt-1 text-secondary fs-5">
                                        <i className="bi bi-gift display-6 fw-bold text-primary text-red"></i>
                                        <span className='ps-2 fs-5'>Stickers</span>
                                    </li>
                                    <li className="pt-2 pt-4 text-primary fs-2 mb-10">
                                        <i className="bi bi-globe display-6 fw-bold text-primary text-black"></i>
                                        <span className='ps-2 text-black'>Worldwide delivery</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="row justify-content-end" data-aos="fade-right">
                                <div className="col-lg-8 text-center">
                                <h5 className="fs-20">
                                    <a href="/" title="Go back home" className="action underline text-red fs-4">
                                        <span>
                                            <i className="bi bi-chevron-double-left display-6 fw-bold text-primary text-red"></i>
                                            GO BACK HOME
                                        </span>                                       
                                    </a> 
                                </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer/>
    </>  
    )  
}
    
export default Available