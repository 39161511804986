import { useCart } from "react-use-cart";
import {isMobile} from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import axios from 'axios';

function NFTCartPreview() {

	  const [etherPrice, setEtherPrice] = useState(0);
		const { t } = useTranslation();

    const {
      items,
      removeItem,
      emptyCart
    } = useCart();

    useEffect(() => {
      getEtherPrice();
    }, []);

    const getEtherPrice = async () => {
      const response = await axios.get(`https://api.etherscan.io/api?module=stats&action=ethprice&apikey=3ZJ1E6NZAZ6IVR1BUZI5MST8FRP21FXNXQ`);
      if(response.data.result)
        setEtherPrice(round((response.data.result.ethusd/10), 2));
    }

    const round = (number, decimalPlaces) => {
      const factorOfTen = Math.pow(10, decimalPlaces)
      return Math.round(number * factorOfTen) / factorOfTen
    }
  
    return (
      <>
      <div className="offcanvas offcanvas-end" tabIndex="1" id="offcanvasCart" aria-labelledby="offcanvasCart">
          <div className="offcanvas-header">
            <h1 className="text-primary" id="offcanvasCartLabel">{t('CART')}</h1>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
          <ul className="list-unstyled">
            {items.map((item) => ( 
                <li className="mt-1" key={item.id}>
                  <div className="row g-xl-6 align-items-center">
                    <a href={"/nft=" + item.id} className="col-3"><img className="img-fluid" src={"./assets/images/NFT/real/JPEG/"+item.id+".jpg"} 
                        alt="Product"/>
                    </a>
                    <div className="col-8">
                      <a href={"/nft=" + item.id} className="text-black text-primary-hover lead"><span className="fs-5">{item.name}</span></a>
                      <br/>
                      <a onClick={() => removeItem(item.id)} className="text-red underline">{t('CART_REMOVE')}</a>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          </div>
          <div className="offcanvas-footer">
          {items.length > 0 ?
                <div className="d-grid gap-1">
                    <center>
                      <span className="text-primary text-black ps-3 py-2 fs-2">Total</span> 
                      <span className="text-black fs-3 ps-3">{round(0.1 * items.length,3)} ETH</span>
                      <br/>
                      <span className="text-black fs-8">(approx. {round(etherPrice* items.length,2)} $)</span>
                    </center>
                  <a href="" onClick={() => emptyCart()} className="btn btn-outline-light rounded-pill mt-3">{t('CART_EMPTY')}</a>
                  <a href="/cart" className="btn btn-primary rounded-pill">{t('CART_CHECKOUT')}</a> 
              </div>
          : ""}
        </div>
      </div>
      </>
    );
  }
  export default NFTCartPreview