import React, { Component } from 'react'  
import Footer from './Footer/Footer'
import Header from './Headers/HeaderCollection'
  

export class NoPage extends Component {
    
    render() {  
        return (

        <body className="bg-red">
            <div>  
            <div id="wrapper"> 
                <div id="content-wrapper" className="d-flex flex-column">  
                    <div id="content"> 
                        <title>404</title>
                    </div>
                </div>  
            </div>
            <Header/>
                <section className="inverted">
                    <div className="d-flex flex-column container min-vh-100 py-20">
                    <div className="row align-items-center justify-content-center justify-content-lg-between my-auto">
                        <div className="col-lg-6 order-lg-2">
                        <img className="img-fluid" src="./assets/images/svg/404.svg" alt="Figure"/>
                        </div>
                        <div className="col-md-8 col-lg-5 order-lg-1 text-center text-lg-start">
                        <h1 className="display-2">Sorry, page not found.</h1>
                        <a href="/" className="btn btn-rounded btn-outline-white rounded-pill">Go back to homepage</a>
                        </div>
                    </div>
                    </div>
                </section>
            <Footer/>
            </div>  
        </body>
        )  
    }  
}
    
export default NoPage