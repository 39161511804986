import { React, useEffect } from 'react';
import Footer from '../Footer/V3/Footer';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';

function Home () {

        const pageTitle = "Ducktail Driver Club | Home";          
        useEffect(() => {
          setGA();
        }, []);

        const setGA = () => {
            ReactGA.send({ hitType: "pageview", page: "/home" });
        };       
        
        function changeDayNight(id){
            var icon = document.getElementById(id);
            var img = document.getElementById('img-'+id);
            if(icon.className === 'bi bi-moon-stars'){
                icon.className = 'bi bi-sun-fill';
                img.src='./assets/images/NFT/editions/'+id+'-night.jpg';
            }
            else 
            {
                icon.className = 'bi bi-moon-stars';
                img.src='./assets/images/NFT/editions/'+id+'.jpg';
            }
        }; 
		
        return (
            <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="offcanvas-wrap">

            <section className="bg-white">
              <div className="container d-flex flex-column foreground min-vh-50 mt-0">
                <div className="row align-items-center justify-content-center my-auto">
                  <div className="col-lg-12 mt-0 text-center">
                    <span className="text-primary fs-1">Ducktail Driver Club</span>
                    <br/>
                    <span className="text-primary fs-1">Editions</span>
                    <br/>                    
                  </div>
                  <div className="col-12 mt-0">
                    <center>
                      <div className="col-4">
                          <img src={"./assets/images/NFT/logo-red-transparent.jpg"}  className="img-fluid" alt="Image"/>
                      </div>
                    </center>
                  </div>
                  <div className="col-12 text-center">
                    <br/>
                    <span className="text-primary fs-3 text-black">Jump on the driver seat and join the adventure now !</span>
                  </div>
                  <div className="col-12 text-center">
                    <br/>
                    <span><i className="bi bi-chevron-double-down text-red fs-1"></i></span>
                  </div>
                </div>
                <div className="row align-items-center justify-content-center my-auto">                    
                    <br/>
                </div>
              </div>
              <span className="scroll-down"></span>
            </section>

            {/* Catch'em All */}
            <section className="cover overflow-hidden bg-red" style={{backgroundImage:'url("./assets/images/NFT/bg-rear-2-white.jpg")', backgroundSize: 'cover'}}>
                <div className="d-flex flex-column min-vh-50 container foreground">
                    <div className="container d-flex flex-column py-5 py-lg-10">
                        <div className="row justify-content-left">
                            <div className="col-md-15 col-lg-8 text-lg-start level-3 align-items-center justify-content-center bg-white"> 
                            <div className="row justify-content-center" data-aos="fade-right">
                                <div className="row py-10">
                                    <div className="overflow-hidden position-relative text-red" data-aos="fade-right">
                                        <h1 className='text-primary fs-1'>Catch'em All</h1>
                                        <br/>
                                    </div>  
                                    <br/>
                                    <div data-aos="fade-left">
                                        <ul className="list-group list-group-minimal">
                                            <li className="list-group-item d-flex align-items-center mb-3">
                                                <i className="display-6 fw-bold text-primary text-red">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-car-front" viewBox="0 0 16 16">
                                                        <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z"/>
                                                        <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"/>
                                                    </svg>
                                                </i>
                                                <span className='ps-2 fs-4'><b>5x</b> Limited Editions of 911 (via Manifold)</span>
                                            </li>
                                            <li className="list-group-item d-flex align-items-center mb-3">
                                                <i className="display-6 fw-bold text-primary text-red">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-fire" viewBox="0 0 16 16">
                                                    <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z"/>
                                                </svg>
                                                </i>
                                                <span className='ps-2 fs-4'><b>5x</b> Burn Events (via Manifold)</span>
                                            </li>
                                            <li className="list-group-item d-flex align-items-center mb-1">
                                                <i className="bi bi-arrow-repeat display-6 fw-bold text-primary text-red"></i>
                                                <span className='ps-2 fs-4'><b>Dynamic NFTs</b></span>
                                            </li>
                                            <li className="list-group-item d-flex align-items-center mb-1 ps-2">
                                                <i className="bi bi-arrow-return-right"></i>
                                                <span className='ps-2 fs-5'>Day & Night Mode <i className="bi bi-sun-fill text-red"></i><span className='text-red'> \ </span><i className="bi bi-moon-fill text-red"></i></span>
                                            </li>
                                            <li className="list-group-item d-flex align-items-center mb-3 ps-2">
                                                <i className="bi bi-arrow-return-right"></i>
                                                <span className='ps-2 fs-5'>Colors changing randomly every couple of seconds <i className="bi bi-paint-bucket text-red"></i></span>
                                            </li>
                                            <li className="list-group-item d-flex align-items-center mb-3">
                                                <i className="bi bi-cash-stack display-6 fw-bold text-primary text-red"></i>
                                                <span className='ps-2 fs-4'><b>0.00911Ξ</b> / each</span>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Hold'em All */}
            <section className="cover overflow-hidden bg-white" style={{backgroundImage:'url("./assets/images/NFT/bg-front-2-sm.jpg")', backgroundSize: 'cover'}}>
                <div className="container py-5 py-lg-20 d-flex flex-column min-vh-50 foreground">
                    <div className="bg-overlay text-black">
                        <div className="row justify-content-end aos-init aos-animate">
                            <div className="col-12 col-lg-8 text-lg-start level-3 align-items-center justify-content-center bg-white">
                                <div className="overflow-hidden position-relative text-red" data-aos="fade-right">
                                    <h1 className='text-primary fs-1'>Hold'em all</h1>     
                                </div>  
                                <br/>
                                <div data-aos="fade-left">
                                    <div className='fs-5 pb-5'>
                                        Hold the 10 NFTs at the end of the race to receive...                                            
                                    </div>
                                    <ul className="list-group list-group-minimal">
                                        <li className="list-group-item d-flex align-items-center mb-2">
                                            <i className="bi bi-gift display-6 fw-bold text-primary text-red"></i>
                                            <span className='ps-2 fs-5'>3x <b>Airdrops</b> of 1/1 Ducktail Driver Club NFT</span>
                                        </li>
                                    </ul>
                                    <div className='fs-5 pb-5'>
                                        or ...                                            
                                    </div>
                                    <ul className="list-group list-group-minimal">
                                        <li className="list-group-item d-flex align-items-center mb-2">
                                            <i className="bi bi-gift display-6 fw-bold text-primary text-red"></i>
                                            <span className='ps-2 fs-5'>1x <b>Airdrop</b> of 1/1 Ducktail Driver Club NFT</span>
                                        </li>
                                        <li className="list-group-item d-flex align-items-center mb-2">
                                            <i className="bi bi-gift display-6 fw-bold text-primary text-red"></i>
                                            <span className='ps-2 fs-5'>1x <b>High-End Metal Print</b></span>
                                        </li>
                                        <li className="list-group-item d-flex align-items-center mb-2">
                                            <i className="bi bi-gift display-6 fw-bold text-primary text-red"></i>
                                            <span className='ps-2 fs-5'>1x <b>Certificat of Authenticity</b></span>
                                        </li>
                                        <li className="list-group-item d-flex align-items-center mb-2">
                                            <i className="bi bi-gift display-6 fw-bold text-primary text-red"></i>
                                            <span className='ps-2 fs-5'>1x <b>Keychain</b></span>
                                        </li>
                                        <li className="list-group-item d-flex align-items-center mb-2">
                                            <i className="bi bi-gift display-6 fw-bold text-primary text-red"></i>
                                            <span className='ps-2 fs-5'><b>Stickers</b></span>
                                        </li>
                                    </ul>
                                </div>  
                            </div>
                            </div>
                            <div className="row justify-content-end" data-aos="fade-left">
                                <div className="col-lg-8 text-center">
                                <h5 className="fs-20">
                                    <a href="/utility" title="See more about utility." className="action underline text-red fs-4"> 
                                        <i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-red"></i>
                                        SEE MORE
                                    </a> 
                                </h5>
                                </div>
                            </div>
                    </div>
                </div>
            </section>

           {/* ROUND 1 */}
           <section className="py-10" id="events" data-aos="flip-left">
                <div className='container'>
                    <div className="row mb-12 text-center">
                        <div className="row d-inline-flex align-items-center mb-0">
                            <center>
                                <div className="col-12 text-red">
                                    <span className='fs-1 text-primary'>Sprint 30</span>
                                    <br/>
                                    <span className='fs-1 text-primary text-black'>Mint</span>
                                </div>
                            </center>
                        </div>
                        <div className="row d-inline-flex align-items-center mb-0">
                            <div className="col-4 col-lg-4 mb-4 mb-lg-0">
                                <div className='align-items-center'>
                                    <a onClick={() => changeDayNight('grid30-911')}>
                                        <span className='ps-2 fs-8 text-primary text-red'>
                                            <i className="bi bi-moon-stars" id="grid30-911"></i>
                                        </span>
                                    </a>
                                </div>
                                <img 
                                    id="img-grid30-911"
                                    src="./assets/images/NFT/editions/grid30-911.jpg"
                                    className="w-100 shadow-1-strong rounded mb-2 border"
                                    alt="Sprint 30 x Grid #1"
                                />
                                <div className='align-items-center'>
                                    <a href="https://app.manifold.xyz/c/ddc-editions-sprint30-grid1" target="_blank"><i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-red"></i>
                                    <span className='ps-2 fs-6'><b>MINT NOW</b></span></a>
                                </div>
                            </div>

                            <div className="col-4 col-lg-4 mb-4 mb-lg-0">
                                <div className='align-items-center'>
                                    <a onClick={() => changeDayNight('grid30-rs')}>
                                        <span className='ps-2 fs-8 text-primary text-red'>
                                            <i className="bi bi-moon-stars" id="grid30-rs"></i>
                                        </span>
                                    </a>
                                </div>
                                <img
                                id="img-grid30-rs"
                                src="./assets/images/NFT/editions/grid30-rs.jpg"
                                className="w-100 shadow-1-strong rounded mb-2 border"
                                alt="Sprint 30 x Grid #2"
                                />
                                <div className='align-items-center'>
                                    <a href="https://app.manifold.xyz/c/ddc-editions-sprint30-grid2" target="_blank"><i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-red"></i>
                                    <span className='ps-2 fs-6'><b>MINT NOW</b></span></a>
                                </div>
                            </div>
                            <div className="col-4 col-lg-4 mb-3 mb-lg-0">
                                <div className='align-items-center'>
                                    <a onClick={() => changeDayNight('grid30-911full')}>
                                        <span className='ps-2 fs-8 text-primary text-red'>
                                            <i className="bi bi-moon-stars" id="grid30-911full"></i>
                                        </span>
                                    </a>
                                </div>
                                <img
                                id="img-grid30-911full"
                                src="./assets/images/NFT/editions/grid30-911full.jpg"
                                className="w-100 shadow-1-strong rounded mb-2 border"
                                alt="Sprint 30 x Grid #3"
                                />
                                <div className='align-items-center'>
                                    <a href="https://app.manifold.xyz/c/ddc-editions-sprint30-grid3" target="_blank"><i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-red"></i>
                                    <span className='ps-2 fs-6'><b>MINT NOW</b></span></a>
                                </div>
                            </div>
                        </div>
                        <div className="row d-inline-flex align-items-center pt-4 mb-2">
                                <div className="col-2 col-lg-2 mb-3 mb-lg-0">
                                </div>                                
                                <div className="col-4 col-lg-4 mb-3 mb-lg-0">
                                    <div className='align-items-center'>
                                        <a onClick={() => changeDayNight('grid30-rsfull')} alt="See day/night">
                                            <span className='ps-2 fs-8 text-primary text-red'>
                                                <i className="bi bi-moon-stars" id="grid30-rsfull"></i>
                                            </span>
                                        </a>
                                    </div>
                                    <img
                                    id='img-grid30-rsfull'
                                    src="./assets/images/NFT/editions/grid30-rsfull.jpg"
                                    className="w-100 shadow-1-strong rounded mb-2 border border"
                                    alt="Sprint 30 x Grid #4"
                                    />
                                    <div className='align-items-center'>
                                        <a href="https://app.manifold.xyz/c/ddc-editions-sprint30-grid4" target="_blank"><i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-red"></i>
                                        <span className='ps-2 fs-6'><b>MINT NOW</b></span></a>
                                    </div>
                                </div>
                                <div className="col-4 col-lg-4 mb-4 mb-lg-0">
                                    <div className='align-items-center'>
                                        <a onClick={() => changeDayNight('grid30-uni')} alt="See day/night">
                                            <span className='ps-2 fs-8 text-primary text-red'>
                                                <i className="bi bi-moon-stars" id="grid30-uni"></i>
                                            </span>
                                        </a>
                                    </div>
                                    <img
                                    id='img-grid30-uni'
                                    src="./assets/images/NFT/editions/grid30-uni.jpg"
                                    className="w-100 shadow-1-strong rounded mb-2 border"
                                    alt="Sprint 30 x Grid #5"
                                    />
                                    <div className='align-items-center'>
                                        <a href="https://app.manifold.xyz/c/ddc-editions-sprint30-grid5" target="_blank"><i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-red"></i>
                                        <span className='ps-2 fs-6'><b>MINT NOW</b></span></a>
                                    </div>
                                </div>
                                <div className="col-2 col-lg-2 mb-3 mb-lg-0">
                                </div>
                        </div>
                        <div className="col-md-12">
                            <div className="fs-4 text-secondary">
                                <ul className="list-unstyled">
                                    <li className="pt-5 pt-md-8">
                                        <i className="bi bi-speedometer text-red"></i>
                                    </li>
                                    <li className="pt-5">
                                        <span className='fs-4'>Starting date: <b>March 26th, 2023 - 7pm GMT</b></span>
                                        <br/>
                                        <span className='fs-4'>Mint Price: <b>0.00911 ETH</b></span>
                                    </li>
                                    <li className="pt-5">
                                        <i className="bi bi-info-circle text-red"></i>
                                    </li>
                                    <li className="pt-1">
                                    <i className="bi bi-arrow-right-short text-red fs-4"></i> <b>Mint 4x</b> of each Limited Editions if you want to hold'em all 
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           {/* ROUND 2 */}
           <section className="py-10" id="events" data-aos="flip-right">
                <div className="container">
                    <div className="row mb-12 text-center">
                        <div className="row d-inline-flex align-items-center mb-0">
                            <div className="col-12 text-red">
                                <center>
                                    <span className='fs-1 text-primary'>Sprint 4</span>
                                    <br/>
                                    <span className='fs-1 text-primary text-black'>Burn Redeem Events</span>
                                </center>
                            </div>
                        </div>
                        <div className="row d-inline-flex align-items-center mb-0">
                            <div className="col-4 col-lg-4 mb-4 mb-lg-4">
                                <div className='align-items-center'>
                                    <a onClick={() => changeDayNight('grid4-911')} alt="See day/night">
                                        <span className='ps-2 fs-8 text-primary text-red'>
                                            <i className="bi bi-moon-stars" id="grid4-911"></i>
                                        </span>
                                    </a>
                                </div>
                                <img 
                                    id='img-grid4-911'
                                    src="./assets/images/NFT/editions/grid4-911.jpg"
                                    className="w-100 shadow-1-strong rounded mb-2 border"
                                    alt="Sprint 4 x Grid #1"
                                />
                                <div className='align-items-center'>
                                    <a href="https://app.manifold.xyz/c/ddc-editions-sprint4-grid1" target="_blank" className='text-red fs-6'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fire" viewBox="0 0 16 16">
                                            <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z"/>
                                        </svg>
                                    <span className='ps-2 fs-6 text-black'><b>REDEEM NOW</b></span></a>
                                </div>
                            </div>

                            <div className="col-4 col-lg-4 mb-4 mb-lg-4">
                                <div className='align-items-center'>
                                    <a onClick={() => changeDayNight('grid4-rs')} alt="See day/night">
                                        <span className='ps-2 fs-8 text-primary text-red'>
                                            <i className="bi bi-moon-stars" id="grid4-rs"></i>
                                        </span>
                                    </a>
                                </div>
                                <img
                                id='img-grid4-rs'
                                src="./assets/images/NFT/editions/grid4-rs.jpg"
                                className="w-100 shadow-1-strong rounded mb-2 border"
                                alt="Sprint 4 x Grid #2"
                                />
                                <div className='align-items-center'>
                                    <a href="https://app.manifold.xyz/c/ddc-editions-sprint4-grid2" target="_blank" className='text-red fs-6'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fire" viewBox="0 0 16 16">
                                            <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z"/>
                                        </svg>
                                    <span className='ps-2 fs-6 text-black'><b>REDEEM NOW</b></span></a>
                                </div>
                            </div>
                            <div className="col-4 col-lg-4 mb-3 mb-lg-4">
                                <div className='align-items-center'>
                                    <a onClick={() => changeDayNight('grid4-911full')} alt="See day/night">
                                        <span className='ps-2 fs-8 text-primary text-red'>
                                            <i className="bi bi-moon-stars" id="grid4-911full"></i>
                                        </span>
                                    </a>
                                </div>
                                <img
                                id='img-grid4-911full'
                                src="./assets/images/NFT/editions/grid4-911full.jpg"
                                className="w-100 shadow-1-strong rounded mb-2 border"
                                alt="Sprint 4 x Grid #3"
                                />
                                <div className='align-items-center'>
                                    <a href="https://app.manifold.xyz/c/ddc-editions-sprint4-grid3" target="_blank" className='text-red'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fire" viewBox="0 0 16 16">
                                            <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z"/>
                                        </svg>
                                        <span className='ps-2 fs-6 text-black'><b>REDEEM NOW</b></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="row d-inline-flex align-items-center mb-0">
                                <div className="col-2 col-lg-2 mb-3 mb-lg-0">
                                </div>
                                <div className="col-4 col-lg-4 mb-3 mb-lg-0">
                                    <div className='align-items-center'>
                                        <a onClick={() => changeDayNight('grid4-rsfull')} alt="See day/night">
                                            <span className='ps-2 fs-8 text-primary text-red'>
                                                <i className="bi bi-moon-stars" id="grid4-rsfull"></i>
                                            </span>
                                        </a>
                                    </div>
                                    <img
                                    id='img-grid4-rsfull'
                                    src="./assets/images/NFT/editions/grid4-rsfull.jpg"
                                    className="w-100 shadow-1-strong rounded mb-2 border"
                                    alt="Sprint 4 x Grid #4"
                                    />
                                    <div className='align-items-center'>
                                        <a href="https://app.manifold.xyz/c/ddc-editions-sprint4-grid4" target="_blank" className='text-red fs-6'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fire" viewBox="0 0 16 16">
                                            <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z"/>
                                        </svg>
                                        <span className='ps-2 fs-6 text-black'><b>REDEEM NOW</b></span></a>
                                    </div>
                                </div>
                                <div className="col-4 col-lg-4 mb-4 mb-lg-0">
                                    <div className='align-items-center'>
                                        <a onClick={() => changeDayNight('grid4-uni')} alt="See day/night">
                                            <span className='ps-2 fs-8 text-primary text-red'>
                                                <i className="bi bi-moon-stars" id="grid4-uni"></i>
                                            </span>
                                        </a>
                                    </div>
                                    <img
                                    id='img-grid4-uni'
                                    src="./assets/images/NFT/editions/grid4-uni.jpg"
                                    className="w-100 shadow-1-strong rounded mb-2 border"
                                    alt="Sprint 4 x Grid #5"
                                    />
                                    <div className='align-items-center'>
                                        <a href="https://app.manifold.xyz/c/ddc-editions-sprint4-grid5" target="_blank" className='text-red fs-6'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fire" viewBox="0 0 16 16">
                                            <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z"/>
                                        </svg>
                                        <span className='ps-2 fs-6 text-black'><b>REDEEM NOW</b></span></a>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="fs-4 text-secondary">
                                        <ul className="list-unstyled">
                                            <li className="pt-5 pt-md-8">
                                                <i className="bi bi-speedometer text-red"></i>
                                            </li>
                                            <li className="pt-5">
                                                <span className='fs-4'>Starting date: <b>May 1st, 2023 - 7pm GMT</b></span>
                                                <br/>
                                                <span className='fs-4'>Burn Price: <b>3x Related Limited Editions NFTs</b></span>
                                            </li>
                                            <li className="pt-5">
                                                <i className="bi bi-info-circle text-red"></i>
                                            </li>
                                            <li className="pt-1">
                                            <i className="bi bi-arrow-right-short text-red fs-4"></i> <b>Mint 4x</b> of each Limited Editions if you want to hold'em all 
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </section>

           {/* AIRDROP */}
           <section className="py-10" id="events" data-aos="flip-right">
                <div className="container">
                    <div className="row">
                        <div className="row d-inline-flex align-items-center mb-0">
                            <div className="col-md-12 text-red text-center">
                                <span className='fs-1 text-primary'>Airdrop</span>
                                <br/>
                                <span className='fs-2 text-primary text-black'>Pick the one you would like</span>
                            </div>
                        </div>
                        <div className="row d-inline-flex align-items-center mb-0">
                        <div className="row text-center">
                            <div className="col-4 mb-4 mb-lg-0">
                                <img
                                src="./assets/images/NFT/real/JPEG/4675.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="4675"
                                />

                                <img
                                src="./assets/images/NFT/real/JPEG/4855.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="4855"
                                />

                                <img
                                src="./assets/images/NFT/real/JPEG/4709.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="4709"
                                />
                            </div>

                            <div className="col-4 mb-4 mb-lg-0">
                                <img
                                src="./assets/images/NFT/real/JPEG/4800.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="4800"
                                />

                                <img
                                src="./assets/images/NFT/real/JPEG/4733.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="4733"
                                />

                                <img
                                src="./assets/images/NFT/real/JPEG/4801.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="4801"
                                />
                            </div>

                            <div className="col-4 mb-4 mb-lg-0">
                                <img
                                src="./assets/images/NFT/real/JPEG/4696.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="4696"
                                />

                                <img
                                src="./assets/images/NFT/real/JPEG/4903.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="4903"
                                />

                                <img
                                src="./assets/images/NFT/real/JPEG/4710.jpg"
                                className="w-100 shadow-1-strong rounded mb-4"
                                alt="4710"
                                />
                            </div>
                        </div>
                            <div className="col-md-12">
                                <div className="fs-4 text-secondary">
                                    <ul className="list-unstyled">
                                        <li className="pt-1 text-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-car-front" viewBox="0 0 16 16">
                                                <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z"/>
                                                <path fill-rule="evenodd" d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"/>
                                            </svg>
                                        </li>
                                        <li className="pt-1">
                                            <i className="bi bi-arrow-right-short text-red fs-4"></i> To receive the airdrop(s), you will have to hold the 10 Ducktail Driver Club Editions at the date of the snapshot.
                                        </li>
                                        <li className="pt-1">
                                            <i className="bi bi-arrow-right-short text-red fs-4"></i> After this, you will be able to tell which airdrop(s) you would like to receive, most probably using Google Forms.
                                            <br/>
                                            <i className="bi bi-arrow-right-short text-red fs-4"></i> If more than one holder is interested for the same 1/1 to be airdropped, the person owning the highest number of editions in total will have the priority.
                                            <br/>
                                            <i className="bi bi-arrow-right-short text-red fs-4"></i> Owning already a 1/1 from my current collections will also give you the priority in that case.
                                        </li>
                                        <li className="pt-5 text-center">
                                            <h5 className="fs-20">
                                                <a href="/available" title="Access to 2022/2023 Calendar." className="action underline text-red fs-4"> 
                                                    <i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-red"></i>
                                                    SEE ALL AVAILABLE FOR AIRDROP
                                                </a> 
                                            </h5>
                                        </li>
                                        <li className="pt-1">
                                            <br/>
                                            <i className="bi bi-info-circle text-red fs-6"></i> 
                                            <span className="fs-6 ps-2">If you go for the Ducktail Driver Club Package including the high-end metal print, you will have to provide a complete address including names. If the address is incorrect or package can't be delivered for any reasons out of our control, we will not be responsible.</span>
                                        </li>
                                        <li className="pt-5 text-center">
                                            <i className="bi bi-paint-bucket text-red fs-4"></i>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           {/* About DUCKTAIL DRIVER CLUB */}  
           <section className="cover overflow-hidden bg-white" style={{backgroundImage:'url("./assets/images/NFT/bg-front-3-sm.jpg")', backgroundSize: 'cover'}}>
                <div className="container d-flex flex-column min-vh-50 foreground">
                    <div className="bg-overlay text-black">
                        <div className="row justify-content-end aos-init aos-animate">
                            <div className="col-12 col-lg-8 pt-15 text-lg-start level-3 align-items-center justify-content-center bg-white">
                                <div className="overflow-hidden position-relative" data-aos="fade-right">
                                    <h1 className='text-primary fs-2 text-blue'>About Ducktail Driver Club</h1> 
                                </div>
                                <div data-aos="fade-left">
                                    <ul className="list-group list-group-minimal">
                                        <li className="list-group-item d-flex align-items-center mb-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-car-front fs-5 text-blue" viewBox="0 0 16 16">
                                                <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z"/>
                                                <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"/>
                                            </svg>
                                            <span className='ps-2 fs-5'><b>ERC-721</b> - Since <b>April 2022</b></span>
                                        </li>
                                        <li className="list-group-item d-flex align-items-center mb-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-car-front fs-5 text-blue" viewBox="0 0 16 16">
                                                <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z"/>
                                                <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"/>
                                            </svg>
                                            <span className='ps-2 fs-5'>Total Supply: <b>4911 Ducktail Driver Club NFTs</b></span>
                                        </li>
                                    </ul>
                                    <ul className="list-group list-group-minimal">
                                        <li className="list-group-item d-flex align-items-center mb-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-car-front fs-5 text-blue" viewBox="0 0 16 16">
                                                <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z"/>
                                                <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"/>
                                            </svg>
                                            <span className='ps-2 fs-5'><b>67</b> Colors inspired from iconic paint colors</span>
                                        </li>
                                        <li className="list-group-item d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-car-front fs-5 text-blue" viewBox="0 0 16 16">
                                                <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z"/>
                                                <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z"/>
                                            </svg>
                                            <span className='ps-2 fs-5'><b>6 Properties</b>:</span>
                                        </li>
                                        <li className="list-group-item d-flex align-items-center ps-10">
                                            <i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-blue"></i>
                                            <span className='ps-2 fs-5'><b>Background</b></span>
                                        </li>
                                        <li className="list-group-item d-flex align-items-center ps-10">
                                            <i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-blue"></i>
                                            <span className='ps-2 fs-5'><b>Body</b></span>
                                        </li>
                                        <li className="list-group-item d-flex align-items-center ps-10">
                                            <i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-blue"></i>
                                            <span className='ps-2 fs-5'><b>Decal</b></span>
                                        </li>
                                        <li className="list-group-item d-flex align-items-center ps-10">
                                            <i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-blue"></i>
                                            <span className='ps-2 fs-5'><b>Emblem Type</b></span>
                                        </li>
                                        <li className="list-group-item d-flex align-items-center ps-10">
                                            <i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-blue"></i>
                                            <span className='ps-2 fs-5'><b>Emblem Color</b></span>
                                        </li>
                                        <li className="list-group-item d-flex align-items-center ps-10">
                                            <i className="bi bi-chevron-double-right display-6 fw-bold text-primary text-blue"></i>
                                            <span className='ps-2 fs-5'><b>Wheel-rim</b></span>
                                        </li>
                                    </ul>
                                    <ul className="list-group list-group-minimal">
                                        <li className="list-group-item d-flex align-items-center mb-2">
                                            <i className="bi bi-box display-6 fw-bold text-primary text-blue"></i>
                                            <span className='ps-2 fs-5'><b>53 Prints</b> already delivered</span>
                                        </li>
                                    </ul>
                                </div>  
                            </div>
                            </div>
                    </div>
                </div>
            </section>

           <section className="py-5" id="events" data-aos="fade-in-down">
                <div className="container">
                    <div className="row sm-12 md-12">
                        <center>
                            <div className="row d-inline-flex align-items-center mb-0">
                                <div className="col-lg-2 mb-4 mb-lg-0"></div>
                                <div className="col-lg-4 mb-4 mb-lg-0">
                                    <img
                                    src="./assets/images/NFT/box-walldecor.jpg"
                                    className="w-100 shadow-1-strong rounded mb-4"
                                    alt="Package walldecor"
                                    />
                                </div>
                                <div className="col-lg-4 mb-3 mb-lg-0">
                                    <img
                                    src="./assets/images/NFT/box-goodies.jpg"
                                    className="w-100 shadow-1-strong rounded mb-4"
                                    alt="Package goodies"
                                    />
                                </div>
                                <div className="col-lg-2 mb-4 mb-lg-0"></div>
                            </div>
                        </center>
                        <div className="col-12" data-aos="fade-in-down">
                            <div className="fs-4">
                                <ul className="list-unstyled">
                                    <li className="pt-1 text-secondary fs-5">
                                        <i className="bi bi-gift text-red fs-5"></i> 
                                        <span className='ps-2 fs-5'>High-end metal print of the 1/1 Ducktail Driver Club NFT you will get as an Airdrop.</span>
                                    </li>
                                    <li className="pt-1 text-secondary fs-6">
                                        <i className="bi bi-arrow-return-right text-red fs-5 px-5"></i><b>Size:</b> 30 x 30cms (11.8 x 11.8 inches).
                                    </li>
                                    <li className="pt-1 text-secondary fs-6">
                                        <i className="bi bi-arrow-return-right text-red fs-5 px-5"></i><b>Composite:</b> High-Resolution Versatile Metal Print (Aluminium).
                                    </li>
                                    <li className="pt-1 text-secondary fs-6">
                                        <i className="bi bi-arrow-return-right text-red fs-5 px-5"></i><b>Frame:</b> Dark American Box.
                                    </li>
                                    <li className="pt-1 text-secondary fs-5">
                                        <i className="bi bi-gift display-6 fw-bold text-primary text-red"></i>
                                        <span className='ps-2 fs-5'>1x Certificat of Authenticity</span>
                                    </li>
                                    <li className="pt-1 text-secondary fs-5">
                                        <i className="bi bi-gift display-6 fw-bold text-primary text-red"></i>
                                        <span className='ps-2 fs-5'>1x Keychain</span>
                                    </li>
                                    <li className="pt-1 text-secondary fs-5">
                                        <i className="bi bi-gift display-6 fw-bold text-primary text-red"></i>
                                        <span className='ps-2 fs-5'>Stickers</span>
                                    </li>
                                    <li className="pt-2 pt-4 text-primary fs-2 mb-10">
                                        <i className="bi bi-globe display-6 fw-bold text-primary text-black"></i>
                                        <span className='ps-2 text-black'>Worldwide delivery</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
        <Footer/>
    </>  
    )  
}
    
export default Home