import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';      
import { CartProvider } from "react-use-cart";
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <CartProvider> 
      <Suspense fallback={<span>Loading...</span>}>
        <App />
      </Suspense>
    </CartProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
