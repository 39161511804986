import React, { Component } from 'react'  
import Footer from './Footer/Footer'
import Header from './Headers/HeaderShopCart'
import NFTCart from '../components/NFTCart'
import { Helmet } from 'react-helmet';
  
export class ShopCart extends Component {
    render() {  
        return (
        <>
          <Helmet>
            <title>Ducktail Driver Club | Cart</title>
          </Helmet>
          <Header/>
          <NFTCart/>
          <Footer/>
        </>
        )  
    }  
}
    
export default ShopCart