import React, { useEffect, useState } from 'react'  
import Footer from '../Footer/Footer'
import Header from '../Headers/HeaderShopCart'
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { ReactSession } from 'react-client-session';
import { useCart } from "react-use-cart";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
  

function PickNFT(){

        const { t } = useTranslation();
        const [showConnectOptions, setShowConnectOptions] = useState(false);
        const [randItemId, setRandItemId] = useState(0);
        const [randItemName, setRandItemName] = useState('');
        const [randItem, setRandItem] = useState('');
        const [nbItemCart, setNbItemCart] = useState(0);
        const [selectedLang, setSelectedLang] = useState(ReactSession.get("lang"));
        const navigate = useNavigate();

        useEffect(() => {
          setGA();
          if(randItemId === 0)
            getRandomNFT();
        }, []);

        const {
          items,
          inCart,
          addItem,
          totalUniqueItems
        } = useCart();

        const setGA = () => {
          ReactGA.send({ hitType: "pageview", page: "/events" });
        };

        const getRandomNFT = async () => {
          const response = await axios.get(`https://ducktaildriverclub.com/ddc-api/nfts/rand/1`);
                setRandItemId(response.data[0].id);
                setRandItemName(response.data[0].name);
          setRandItem(response.data[0]);			
        }

        const DoShowConnectOptions = async (e) => {
          e.preventDefault();
          setShowConnectOptions(true);
        }

        const goToCart = async () => {
          addItem(randItem);
          navigate("/cart");
        }

        const addUniqueItemInCart = (nft) => {
            if(!inCart(nft.id)){
                getRandomNFT();
                addItem(randItem);
                setNbItemCart(totalUniqueItems);
            }
        }

        return (
        <>
        <Helmet>
          <title>Ducktail Driver Club | {t('HOME_EVENT_TITLE')}</title>
        </Helmet>
        <Header/>
        <div className="offcanvas-wrap">
          <section>
            <div className="container">
              <div className="row justify-content-center mb-2">
                <div className="col-lg-12 text-red">
                  <h1 className="text-primary text-black pt-2"> 1/2 Join the club</h1>
                </div> 
              </div>
              <div className="row g-1 justify-content-center px-2 pt-2" id="grid1" data-isotope>
                <div className="col-12 filter-design mb-2">
                  <div className="row text-center text-md-start">
                    <p className='text-secondary'>
                      To be part of the Ducktail Driver Club, you need to own at least one Ducktail Driver Club NFT. This is it.
                      <br/>
                      Owning more than one will automatically increase your chance to win a giveaway.
                      <br/><br/>
                      You have two options, you can buy randomly or pick your Ducktail Driver Club NFT(s).
                    </p>
                    <div className="row col-12 text-center">
                      <div className="col-6">
                        <p className='text-right'>
                          <a href="" className='fs-6 text-secondary text-red'>
                            <span>
                              <i className="bi bi-chevron-double-right mx-1"></i>
                              Buy random
                            </span>
                          </a>
                            <select class="custom-select ms-1" id="inlineFormCustomSelect">
                              <option selected>1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                            </select>
                            <span>
                              <a href="" className='fs-6 text-secondary ms-1 text-red'>
                                NFT(s)
                              </a>
                            </span>
                        </p>
                      </div>
                      <div className="col-6" onClick={e => DoShowConnectOptions(e)}>
                        <a href="enter-the-club" className='fs-6 text-secondary text-right text-red'>
                          <i className="bi bi-chevron-double-right mx-1"></i>
                          Pick manually
                        </a>                                                   
                      </div>                 
                    </div>
                    {
                      showConnectOptions ?
                      <div>
                        <br/><br/>
                        <div className="row justify-content-center col-12">
                          <div className="col-7 col-md-7 col-lg-5 col-xl-4">
                                  { randItemId === 0 ?
                                      <button className="btn rounded-pill btn-white btn-outline-danger text-red shadow">
                                          <i className="bi bi-clock-history"></i> 
                                          <span className='ms-1'> Loading</span>
                                      </button>
                                      : <span><a href={"/nft="+randItemId} target="_blank" title={randItemName}><img src={"./assets/images/NFT/real/JPEG/"+randItemId+".jpg"}  className="img-fluid" alt="Image"/></a></span>} 
                          </div>
                          <div className="col-5 col-md-3">
                              <ul class="nav flex-column">
                                <li class="nav-item pt-sm-10 pt-lg-20">
                                    <a onClick={(e) => getRandomNFT(e)} title="Roll the dice to view another random NFT.">
                                        <button className="btn rounded-pill btn-white btn-outline-black text-black">
                                            <i className="bi bi-dice-6 mx-sm-1"></i>
                                            <span className='d-none d-md-inline'>{t('SHOP_CRITERIA_RANDOM')}</span>
                                        </button>
                                    </a>
                                </li>
                                <li class="nav-item mt-2">
                                    <a title="Add to cart.">
                                        <button onClick={() => addUniqueItemInCart(randItem)} title={items.find(n => n.id == randItemId) != null ? "Already in the cart!" : "Add to cart"} type="button" className={randItem.sold === 1 ? "btn rounded-pill btn-white btn-outline-black text-black" : items.find(n => n.id == randItem.id) != null ? "btn rounded-pill btn-white btn-outline-black text-black disabled" : "btn rounded-pill btn-white btn-outline-black text-black"}>
                                            <i id={randItemId} className='bi bi-cart-plus mx-sm-1'></i>
                                            <span className='d-none d-md-inline'>{t('NFT_ADD_TO_CART')}</span>
                                        </button>
                                    </a>
                                </li>
                              </ul>
                            </div>
                            <center>
                              <div className="col-8 col-sm-4 pt-2 pt-xl-4">
                                <span className="text-red justify-content-center">
                                    <span>
                                        <button className="btn rounded-pill btn-white btn-outline-black text-black" data-bs-toggle="offcanvas" href="#offcanvasCart"
                                        aria-controls="offcanvasCart">
                                            <i className="bi bi-cart3"></i>
                                            <span className='text-black ms-0 ms-sm-1 d-sm-none'>
                                            <b>{totalUniqueItems}</b>
                                            </span> 
                                            <span className='text-black ms-0 ms-sm-1 d-none d-md-inline'>
                                            {t('CART')} (<b>{totalUniqueItems}</b>)
                                            </span> 
                                        </button>
                                        <a onClick={(e) => goToCart(e)} title="Proceed to minting!">
                                            <button className="btn btn-white btn-primary btn-outline-red rounded-pill shadow ms-sm-1">
                                                <i className="bi bi-wallet2 text-red"></i> 
                                                <span className='ms-1 text-red fs-6'><b>Paiement</b></span>
                                                <i className="bi bi-arrow-right ms-1 text-red"></i> 
                                            </button>
                                        </a>
                                    </span> 
                                </span>
                              </div>              
                              <br/>
                            </center>              
                        </div>
                        </div> : <div></div>
                    }
                  </div>
                </div>
              </div>
              <br/><br/>
            </div>
            </section>
            <br/><br/>
        </div>

        <Footer/>
        </>
        )  
    }
export default PickNFT