import React, { Component } from 'react'

export class HeaderHome extends Component {  
    render() {  
        return (
            <div>
                <nav id="mainNav" className="navbar navbar-expand-md navbar-sticky navbar-dark">
                    <div className="container justify-content-center my-auto fs-3">
                        <center>
                            <ul className="navbar-nav navbar-nav-secondary text-primary text-red">
                                <li className="nav-item">
                                    <a href="/" className="navbar-brand">
                                        <img src="./assets/images/logo/logo-red.png" alt="Logo"/>
                                    </a>
                                </li>
                            </ul>
                        </center>
                    </div>
                </nav>
            </div>
        )  
    }
}  
  
export default HeaderHome