import Footer from '../Footer/V3/Footer';
import Header from '../Headers/V3/Header';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import axios from 'axios';
import React,{ useState, useEffect} from 'react';

function Available () {
    
        const nfts = 0;
        const pageTitle = "Ducktail Driver Club | Available for Drop";  
        const [allItems, setAllItems] = useState(nfts);        
        const [currentItems, setCurrentItems] = useState(allItems);

        useEffect(() => {
          setGA();
          getNFTs();
        }, []);

        const getNFTs = async () => {
            const response = await axios.get(`https://ducktaildriverclub.com/ddc-api/nfts`);
            setCurrentItems(response.data.filter(nft => nft.sold === 0));
        }

        const setGA = () => {
            ReactGA.send({ hitType: "pageview", page: "/available" });
        };        
        <Header></Header>
		
        return (
            <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="offcanvas-wrap">

            <section className="bg-white">
              <div className="container d-flex flex-column foreground min-vh-100 mt-0">
                <div className="row align-items-center justify-content-center my-auto">
                    
                    <div className="row d-inline-flex align-items-center mb-0">
                        <center>
                        <div className="row justify-content-end" data-aos="fade-right">
                            <div className="col-12 text-center">
                            <h5 className="fs-7">
                                <a href="/" title="Go back home" className="action underline text-black fs-6">
                                    <span>
                                        <i className="bi bi-chevron-double-left display-6 fw-bold text-primary text-black"></i>
                                        GO BACK HOME
                                    </span>                                       
                                </a> 
                            </h5>
                            </div>
                        </div>

                        <div className="row d-inline-flex text-center align-items-center mb-0 pt-5 pt-md-10">
                                <div className="col-12 text-red">
                                    <span className='fs-1 text-primary'>Available for Airdrop</span>
                                </div>
                        </div>
                        </center>

                        {/* 911 Emblems */}
                        <div class="row pt-7">
                            <span className='fs-1 text-primary text-black'>911 Emblems</span>
                        </div>
                        <div className="col-12 d-block mb-10">
                            <div className="row g-2" data-aos="fade-up">
                            {
                                currentItems && currentItems.length > 0 && currentItems.filter(nft => nft.emblem === '911').map((nft, index) => (
                                <div className="col-5 col-md-2 d-block" key={index}>
                                    <div className='product'>
                                        <figure className="product-image">
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <b>{ nft.name }</b>
                                        </figure>
                                    </div>
                                </div>
                                )) 
                                }   
                            </div> 
                            <br/>
                        </div>

                        {/* RS Emblems */}
                        <div class="row pt-5">
                            <span className='fs-1 text-primary text-black'>Rs Emblems</span>
                        </div>
                        <div className="col-12 d-block mb-10">
                            <div className="row g-2" data-aos="fade-up" data-aos-delay="150">
                            {
                                currentItems && currentItems.length > 0 && currentItems.filter(nft => nft.emblem === 'RS').map((nft, index) => (
                                <div className="col-5 col-md-2 d-block" key={index}>
                                    <div className='product'>
                                        <figure className="product-image">
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <b>{ nft.name }</b>
                                        </figure>
                                    </div>
                                </div>
                                )) 
                                }   
                            </div> 
                            <br/>
                        </div>

                        {/* UNI */}
                        <div class="row pt-5">
                            <span className='fs-1 text-primary text-black'>Uni</span>
                        </div>
                        <div className="col-12 d-block mb-10">
                            <div className="row g-2" data-aos="fade-up" data-aos-delay="150">
                            {
                                currentItems && currentItems.length > 0 && currentItems.filter(nft => nft.emblem != 'RS' && nft.emblem != '911' && nft.background === nft.decal).map((nft, index) => (
                                <div className="col-5 col-md-2 d-block" key={index}>
                                    <div className='product'>
                                        <figure className="product-image">
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <b>{ nft.name }</b>
                                        </figure>
                                    </div>
                                </div>
                                )) 
                                }   
                            </div> 
                            <br/>
                        </div>

                        {/* RED COLORS */}
                        <div class="row pt-5">
                            <span className='fs-1 text-primary text-red'>Red Colors</span>
                        </div>
                        <div className="col-12 d-block mb-10">
                            <div className="row g-2" data-aos="fade-up" data-aos-delay="150">
                            {
                                currentItems && currentItems.length > 0 && currentItems.filter(nft =>  nft.emblem != 'RS' && nft.emblem != '911' && nft.color === 'red').map((nft, index) => (
                                <div className="col-5 col-md-2 d-block" key={index}>
                                    <div className='product'>
                                        <figure className="product-image">
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <b>{ nft.name }</b>
                                        </figure>
                                    </div>
                                </div>
                                )) 
                                }   
                            </div> 
                            <br/>
                        </div>

                        {/* BLUE COLORS */}
                        <div class="row pt-5">
                            <span className='fs-1 text-primary text-blue'>Blue</span>
                        </div>
                        <div className="col-12 d-block mb-10">
                            <div className="row g-2" data-aos="fade-up" data-aos-delay="150">
                            {
                                currentItems && currentItems.length > 0 && currentItems.filter(nft =>  nft.emblem != 'RS' && nft.emblem != '911' && nft.color === 'blue').map((nft, index) => (
                                <div className="col-5 col-md-2 d-block" key={index}>
                                    <div className='product'>
                                        <figure className="product-image">
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <b>{ nft.name }</b>
                                        </figure>
                                    </div>
                                </div>
                                )) 
                                }   
                            </div> 
                            <br/>
                        </div>

                        {/* GREEN COLORS */}
                        <div class="row pt-5">
                            <span className='fs-1 text-primary text-green'>Green</span>
                        </div>
                        <div className="col-12 d-block mb-10">
                            <div className="row g-2" data-aos="fade-up" data-aos-delay="150">
                            {
                                currentItems && currentItems.length > 0 && currentItems.filter(nft =>  nft.emblem != 'RS' && nft.emblem != '911' && nft.color === 'green').map((nft, index) => (
                                <div className="col-5 col-md-2 d-block" key={index}>
                                    <div className='product'>
                                        <figure className="product-image">
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <b>{ nft.name }</b>
                                        </figure>
                                    </div>
                                </div>
                                )) 
                                }   
                            </div> 
                            <br/>
                        </div>

                        {/* Yellow COLORS */}
                        <div class="row pt-5">
                            <span className='fs-1 text-primary text-yellow'>Yellow</span>
                        </div>
                        <div className="col-12 d-block mb-10">
                            <div className="row g-2" data-aos="fade-up" data-aos-delay="150">
                            {
                                currentItems && currentItems.length > 0 && currentItems.filter(nft =>  nft.emblem != 'RS' && nft.emblem != '911' && nft.color === 'yellow').map((nft, index) => (
                                <div className="col-5 col-md-2 d-block" key={index}>
                                    <div className='product'>
                                        <figure className="product-image">
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <b>{ nft.name }</b>
                                        </figure>
                                    </div>
                                </div>
                                )) 
                                }   
                            </div> 
                            <br/>
                        </div>

                        {/* Pink COLORS */}
                        <div class="row pt-5">
                            <span className='fs-1 text-primary text-pink'>Pink</span>
                        </div>
                        <div className="col-12 d-block mb-10">
                            <div className="row g-2" data-aos="fade-up" data-aos-delay="150">
                            {
                                currentItems && currentItems.length > 0 && currentItems.filter(nft =>  nft.emblem != 'RS' && nft.emblem != '911' && nft.color === 'pink').map((nft, index) => (
                                <div className="col-5 col-md-2 d-block" key={index}>
                                    <div className='product'>
                                        <figure className="product-image">
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <b>{ nft.name }</b>
                                        </figure>
                                    </div>
                                </div>
                                )) 
                                }   
                            </div> 
                            <br/>
                        </div>

                        {/* Purple COLORS */}
                        <div class="row pt-5">
                            <span className='fs-1 text-primary text-purple'>Purple</span>
                        </div>
                        <div className="col-12 d-block mb-10">
                            <div className="row g-2" data-aos="fade-up" data-aos-delay="150">
                            {
                                currentItems && currentItems.length > 0 && currentItems.filter(nft =>  nft.emblem != 'RS' && nft.emblem != '911' && nft.color === 'purple').map((nft, index) => (
                                <div className="col-5 col-md-2 d-block" key={index}>
                                    <div className='product'>
                                        <figure className="product-image">
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <b>{ nft.name }</b>
                                        </figure>
                                    </div>
                                </div>
                                )) 
                                }   
                            </div> 
                            <br/>
                        </div>

                        {/* Orange COLORS */}
                        <div class="row pt-5">
                            <span className='fs-1 text-primary text-orange'>Orange</span>
                        </div>
                        <div className="col-12 d-block mb-10">
                            <div className="row g-2" data-aos="fade-up" data-aos-delay="150">
                            {
                                currentItems && currentItems.length > 0 && currentItems.filter(nft =>  nft.emblem != 'RS' && nft.emblem != '911' && nft.color === 'orange').map((nft, index) => (
                                <div className="col-5 col-md-2 d-block" key={index}>
                                    <div className='product'>
                                        <figure className="product-image">
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <b>{ nft.name }</b>
                                        </figure>
                                    </div>
                                </div>
                                )) 
                                }   
                            </div> 
                            <br/>
                        </div>

                        {/* Brown COLORS */}
                        <div class="row pt-5">
                            <span className='fs-1 text-primary text-black'>Brown</span>
                        </div>
                        <div className="col-12 d-block mb-10">
                            <div className="row g-2" data-aos="fade-up" data-aos-delay="150">
                            {
                                currentItems && currentItems.length > 0 && currentItems.filter(nft =>  nft.emblem != 'RS' && nft.emblem != '911' && nft.color === 'brown').map((nft, index) => (
                                <div className="col-5 col-md-2 d-block" key={index}>
                                    <div className='product'>
                                        <figure className="product-image">
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <b>{ nft.name }</b>
                                        </figure>
                                    </div>
                                </div>
                                )) 
                                }   
                            </div> 
                            <br/>
                        </div>

                        {/* Others */}
                        <div class="row pt-5">
                            <span className='fs-1 text-primary text-black'>Others</span>
                        </div>
                        <div className="col-12 d-block mb-10">
                            <div className="row g-2" data-aos="fade-up" data-aos-delay="150">
                            {
                                currentItems && currentItems.length > 0 && currentItems.filter(nft =>  nft.emblem != 'RS' && nft.emblem != '911' && nft.color === 'other').map((nft, index) => (
                                <div className="col-5 col-md-2 d-block" key={index}>
                                    <div className='product'>
                                        <figure className="product-image">
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                            <b>{ nft.name }</b>
                                        </figure>
                                    </div>
                                </div>
                                )) 
                                }   
                            </div> 
                            <br/>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center my-auto">                    
                    <br/>
                </div>
              </div>
              <span className="scroll-down"></span>
            </section>
        </div>
        <Footer/>
    </>  
    )  
}
    
export default Available