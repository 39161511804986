import Footer from './Footer/Footer'
import HeaderShop from './Headers/HeaderShop'
import NFTCartPreview from '../components/NFTCartPreview'
import { useCart } from "react-use-cart"
import React,{ useState, useEffect, useRef} from 'react'
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import {isMobile} from 'react-device-detect';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

function Shop ({ nfts }) {

        const pageTitle = "Ducktail Driver Club | Shop";
        const [allItems, setAllItems] = useState(nfts);
        const [filteredItems, setFilteredItems] = useState(allItems);
        const [currentItems, setCurrentItems] = useState(allItems);
        const [selectedColor, setSelectedColor] = useState('All');
        const [currentSelectedCheckbox, setCurrentSelectedCheckbox] = useState('rand-500');
        const [page, setPage] = useState(0);
        const [nbRand, setNbRand] = useState(0);
        const nftsPerPage = isMobile ? 6 : 24;
        const numberOfNFTsVisited = page * nftsPerPage;
        const totalPages = Math.ceil(currentItems.length / nftsPerPage);
        const encrageTop = useRef();
        const { t } = useTranslation();

        const {
          items,
          inCart,
          removeItem,
          emptyCart,
          addItem
        } = useCart();

        const setGA = () => {
          ReactGA.send({ hitType: "pageview", page: "/shop" });
        };
        
        useEffect(() => {
          setGA();
          if(selectedColor === 'All')
            getNFTs();
          setFilteredItems(filterByColor(allItems));
          setCurrentItems(filterByColor(allItems));
        }, [selectedColor, nbRand]);

        const getNFTs = async () => {
          if(nbRand === 0 ){
            const response = await axios.get(`https://ducktaildriverclub.com/ddc-api/nfts`);
            setSelectedColor('');
            setAllItems(response.data);
            setCurrentItems(response.data.filter(nft => nft.sold === 0));
            setFilteredItems(response.data.filter(nft => nft.sold === 0));
          }
          else  
          {
            const response = await axios.get(`https://ducktaildriverclub.com/ddc-api/nfts/rand/${nbRand}`);
            setSelectedColor('All');
            setAllItems(response.data);
            setCurrentItems(response.data);
            setFilteredItems(response.data);
          }
        }

        const addUniqueItemInCart = (nft) => {
          if(!inCart(nft.id)){
            addItem(nft);
          }
        }
        
        const filterByColor = (filteredData) => {
          // Avoid filter for empty string
          if (selectedColor.length === 0) {
            return filteredData.filter(
              nft => nft.sold === 0
            );
          }
        
          if(selectedColor === 'emblem-911')
          {
            filteredNFTs = filteredData.filter(
              nft => nft.emblem === '911' && nft.sold === 0
            );
          } else if(selectedColor === 'emblem-rs')
          {
            filteredNFTs = filteredData.filter(
              nft => nft.emblem === 'RS' && nft.sold === 0 
            );
          } else if(selectedColor === 'unicolor') {
            filteredNFTs = filteredData.filter(
              nft => nft.unicolor === 1 && nft.sold === 0
            );
          } else if(selectedColor === 'sold') {
            filteredNFTs = filteredData.filter(
              nft => nft.sold === 1
            );
          }
          else {
            var filteredNFTs = filteredData.filter(
              nft => selectedColor.toString().toLowerCase().indexOf(nft.color.split(" ")) !== -1
            );
          }

          if(filteredNFTs.length <= nftsPerPage)
            setPage(0);
          return filteredNFTs;
        };

        const updateSelectedCheckbox = (name, param) => {
          if(currentSelectedCheckbox != '' && name != currentSelectedCheckbox)
            document.getElementById(currentSelectedCheckbox).checked = false;

          if (name === 'rand-500'){
            setSelectedColor('All');
            setNbRand(500);
          }
          else
          {
            setSelectedColor(param === '' || name === 'rand-none' ? 'All' : param);
            setNbRand(0);
          }
          setCurrentSelectedCheckbox(name);
        }

        const changePage = ({ selected }) => {
          encrageTop.current.scrollIntoView({ behavior: "auto" });
          setPage(selected);
        };

        return (
        <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <HeaderShop/>
        <NFTCartPreview/>
        <script src="./assets/js/vendor.bundle.js"></script>
        <div ref={encrageTop} className="offcanvas-wrap">
            <section className="pt-10 pt-xl-15">
              <div className="container">
                <div className="row g-3 g-md-1 align-items-end mb-5">
                  <span className='text-primary fs-1 pt-10 pt-md-5 text-red'>{t('SHOP')}</span>
                  <span className='text-secondary fs-7 text-black'>{t('SHOP_SUBTITLE')}.</span>
                  {isMobile ? <span className='text-secondary fs-7 text-red'>{t('MOBILE_DEVICE_WARNING')}.</span> : ""}
                  <span className='text-primary fs-4 mt-5 text-black'>{selectedColor == 'sold' ? t('SHOP_TITLE_ALREADYMINTED')+" ("+currentItems.length+")" :(nbRand != 4911 || nbRand === 0) ? t('SHOP_TITLE_AVAILABLE')+" ("+currentItems.length+")" : t('SHOP_CRITERIA_RANDOM')+" ("+nbRand+")"} {selectedColor == 'sold' ? '' : selectedColor === '' ? "" : selectedColor === 'All' ?  t('SHOP_TITLE_AVAILABLECOLORS') : selectedColor === 'emblem-911' ? t('SHOP_TITLE_EMBLEM911') : selectedColor === 'emblem-rs' ? t('SHOP_TITLE_EMBLEMRS') : t('SHOP_TITLE_COLOR')+selectedColor+""}</span>
                </div>
              </div>

              <div className="container">
                <div className="row col-12 justify-content-between">
                  {/* Filters */}
                  <div className="col-2 d-block" data-aos="fade-right" data-aos-delay="200">
                  <div className="button-group filter-button-group">
                    <div className="widget">
                      <span className="d-flex eyebrow text-muted mb-3"><i className="bi bi-gear-wide-connected text-red fs-6"></i><b><span className="ps-1 text-black">{t('SHOP_CRITERIA_VARIATIONS')}</span></b></span>
                      <ul className="list-unstyled">
                        <li>
                          <div className="form-check form-check-color">
                            <input className="form-check-input" type="checkbox" value="" id="emblem-911" onChange={(e) => {
                                                                                                                          e.target.checked
                                                                                                                          ? updateSelectedCheckbox('emblem-911','emblem-911')
                                                                                                                          : updateSelectedCheckbox('','');
                                                                                                                        }}/>
                              <label className="form-check-label" htmlFor="emblem-911">
                                <span className="bg-red"></span>{t('COLLECTION_VARIATIONS_911EMBLEM')}
                            </label>
                           </div>
                        </li>
                        <li>
                          <div className="form-check form-check-color">
                            <input className="form-check-input" type="checkbox" value="" id="emblem-rs" onChange={(e) => {
                                                                                                                                e.target.checked
                                                                                                                                ? updateSelectedCheckbox('emblem-rs','emblem-rs')
                                                                                                                                  : updateSelectedCheckbox('','');
                                                                                                                            }}/>
                              <label className="form-check-label" htmlFor="emblem-rs">
                                <span className="bg-red"></span>{t('COLLECTION_VARIATIONS_RSEMBLEM')}
                            </label>
                           </div>
                        </li>
                        <li>
                          <div className="form-check form-check-color">
                            <input className="form-check-input" type="checkbox" value="" id="unicolor" onChange={(e) => {e.target.checked
                                                                                                                                ? updateSelectedCheckbox('unicolor','unicolor')
                                                                                                                                : updateSelectedCheckbox('', '');
                                                                                                                            }}/>
                              <label className="form-check-label" htmlFor="unicolor">
                                <span className="bg-red"></span> {t('COLLECTION_VARIATIONS_UNICOLOR')}
                            </label>                          
                            </div>
                        </li>
                        <li>
                          <div className="form-check form-check-color">
                            <input className="form-check-input" type="checkbox" value="" id="sold" onChange={(e) => {
                                                                                                                          e.target.checked
                                                                                                                          ? updateSelectedCheckbox('sold','sold')
                                                                                                                          : updateSelectedCheckbox('','');
                                                                                                                        }}/>
                              <label className="form-check-label" htmlFor="sold">
                                <span className="bg-red"></span>{t('SHOP_CRITERIA_MINTED')}
                            </label>
                           </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <br/>
                  <div className="button-group filter-button-group pt-2">
                    <div className="widget">
                    <span className="d-flex eyebrow text-muted mb-3"><i className="bi bi-gear-wide-connected text-red fs-6"></i><b><span className="ps-1 text-black">{t('SHOP_CRITERIA_RANDOM')}</span></b></span>
                      <ul className="list-unstyled">
                        <li className="mt-1">
                          <div className="form-check form-check-color">
                              <input className="form-check-input" type="checkbox" value="" id="rand-500" onChange={(e) => {e.target.checked
                                                                                                                                ? updateSelectedCheckbox('rand-500','500')
                                                                                                                                : updateSelectedCheckbox('', '');
                                                                                                                            }}/>
                              <label className="form-check-label" htmlFor="rand-500">
                                <span className="bg-red"></span> 500
                              </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-color">
                            <input className="form-check-input" type="checkbox" value="" id="rand-none" onChange={(e) => {
                                                                                                                          e.target.checked
                                                                                                                          ? updateSelectedCheckbox('rand-none','4911')
                                                                                                                          : updateSelectedCheckbox('','');
                                                                                                                        }}/>
                              <label className="form-check-label" htmlFor="rand-none">
                                <span className="bg-red"></span>{t('SHOP_CRITERIA_NONE')}
                            </label>
                           </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="button-group filter-button-group">
                    <div className="widget mt-5">
                      <span className="d-flex eyebrow text-muted mb-3"><i className="bi bi-gear-wide-connected text-red fs-6"></i><b><span className="ps-1 text-black">{t('SHOP_CRITERIA_COLOR')}</span></b></span>
                      <ul className="list-unstyled">
                        <li>
                          <div className="form-check form-check-color">
                            <input className="form-check-input" type="checkbox" value="" id="color-all" onChange={(e) => {
                                                                                                                                e.target.checked
                                                                                                                                ? updateSelectedCheckbox('color-all','All')
                                                                                                                                : updateSelectedCheckbox('','');
                                                                                                                            }}/>
                              <label className="form-check-label" htmlFor="color-all">
                                <span className="bg-black"></span>{t('SHOP_CRITERIA_ALL')}
                            </label>
                           </div>
                        </li>
                        <li>
                          <div className="form-check form-check-color">
                            <input className="form-check-input" type="checkbox" value="" id="color-red" onChange={(e) => {e.target.checked
                                                                                                                                ? updateSelectedCheckbox('color-red','Red')
                                                                                                                                : updateSelectedCheckbox('', '');
                                                                                                                            }}/>
                              <label className="form-check-label" htmlFor="color-red">
                                <span className="bg-red"></span> {t('COLLECTION_COLOR_RED')}
                            </label>                          
                            </div>
                        </li>
                        <li>
                          <div className="form-check form-check-color">
                          <input className="form-check-input" type="checkbox" value="" id="color-orange" onChange={(e) => {e.target.checked
                                                                                                                                ? updateSelectedCheckbox('color-orange','Orange')
                                                                                                                                : updateSelectedCheckbox('', '');
                                                                                                                            }}/>
                              <label className="form-check-label" htmlFor="color-orange">
                                <span className="bg-orange"></span> Orange
                            </label>
                          </div>
                        </li>
                        <li className="mt-1">
                          <div className="form-check form-check-color">
                          <input className="form-check-input" type="checkbox" value="" id="color-yellow" onChange={(e) => {e.target.checked
                                                                                                                                ? updateSelectedCheckbox('color-yellow','Yellow')
                                                                                                                                : updateSelectedCheckbox('', '');
                                                                                                                            }}/>
                            <label className="form-check-label" htmlFor="color-yellow">
                                <span className="bg-yellow"></span> {t('COLLECTION_COLOR_YELLOW')}
                            </label>
                          </div>
                        </li>
                        <li className="mt-1">
                          <div className="form-check form-check-color">
                              <input className="form-check-input" type="checkbox" value="" id="color-green" onChange={(e) => {e.target.checked
                                                                                                                                ? updateSelectedCheckbox('color-green','Green')
                                                                                                                                : updateSelectedCheckbox('', '');
                                                                                                                            }}/>
                              <label className="form-check-label" htmlFor="color-green">
                                <span className="bg-green"></span> {t('COLLECTION_COLOR_GREEN')}
                              </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check form-check-color">
                            <input className="form-check-input" type="checkbox" value="" id="color-blue" onChange={(e) => {
                                                                                                                                e.target.checked
                                                                                                                                ? updateSelectedCheckbox('color-blue','Blue')
                                                                                                                                : updateSelectedCheckbox('','');
                                                                                                                            }}/>
                              <label className="form-check-label" htmlFor="color-blue">
                                <span className="bg-blue"></span> {t('COLLECTION_COLOR_BLUE')}
                            </label>
                           </div>
                        </li>
                        <li>
                          <div className="form-check form-check-color">
                            <input className="form-check-input" type="checkbox" value="" id="color-purple" onChange={(e) => {
                                                                                                                                e.target.checked
                                                                                                                                ? updateSelectedCheckbox('color-purple','Purple')
                                                                                                                                : updateSelectedCheckbox('', '');
                                                                                                                            }}/>
                              <label className="form-check-label" htmlFor="color-purple">
                                <span className="bg-indigo"></span> {t('COLLECTION_COLOR_PURPLE')}
                            </label>
                          </div>
                        </li>
                        <li className="mt-1">
                          <div className="form-check form-check-color">
                            <input className="form-check-input" type="checkbox" value="" id="color-pink" onChange={(e) => {e.target.checked
                                                                                                                                ? updateSelectedCheckbox('color-pink','Pink')
                                                                                                                                : updateSelectedCheckbox('', '');
                                                                                                                            }}/>
                              <label className="form-check-label" htmlFor="color-pink">
                                <span className="bg-pink"></span> {t('COLLECTION_COLOR_PINK')}
                              </label>
                          </div>
                        </li>
                        <li className="mt-1">
                          <div className="form-check form-check-color">
                              <input className="form-check-input" type="checkbox" value="" id="color-other" onChange={(e) => {e.target.checked
                                                                                                                                ? updateSelectedCheckbox('color-other','Other')
                                                                                                                                : updateSelectedCheckbox('', '');
                                                                                                                            }}/>
                              <label className="form-check-label" htmlFor="color-other">
                                <span className="bg-black"></span> {t('COLLECTION_COLOR_OTHERS')}
                              </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  </div>

                  {/* NFTs */}
                  <div className="col-8 d-block mb-10">
                    <div className="row g-2" data-aos="fade-up" data-aos-delay="350">
                    {
                      currentItems && currentItems.length > 0 && currentItems.slice(numberOfNFTsVisited, numberOfNFTsVisited + nftsPerPage).map((nft, index) => (
                        <div className="col-5 col-md-3 d-block" key={index}>
                            <div className='product'>
                                <figure className="product-image">
                                    <a href={"/nft=" + nft.id} target="_blank">
                                        <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                        <img src={"./assets/images/NFT/real/JPEG/"+nft.id+".jpg"} alt="Image" />
                                    </a>
                                    <a className="product-title" target="_blank" href={"/nft=" + nft.id}><b>{ nft.name }</b></a>
                                    <span className='product-price' align="right">
                                      <svg stroke-width="0" viewBox="0 0 320 512" className={ nft.sold === 1 ? "text-grey text-20 d-none" : "text-grey text-20"}  height="1.1em" width="1.1em" xmlns="http://www.w3.org/2000/svg"><path d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z"></path></svg>
                                      {nft.sold === 1 ? 'Minted' : nft.price }  
                                    </span>
                                    <div id={nft.id} align="right">
                                      <button onClick={() => addUniqueItemInCart(nft)} title={items.find(n => n.id == nft.id) != null ? "Already in the cart!" : "Add to cart"} type="button" className={nft.sold === 1 ? "btn btn-sm btn-outline-dark btn-icon rounded-circle d-none" : items.find(n => n.id == nft.id) != null ? "btn btn-sm btn-outline-dark btn-icon rounded-circle disabled" : "btn btn-sm btn-outline-dark btn-icon rounded-circle"}>
                                        <i id={nft.id} className={items.find(n => n.id == nft.id) != null ? 'bi bi-cart-check-fill' : nft.sold === 1 ? 'bi bi-cart-fill' :  'bi bi-cart-plus'}></i>
                                      </button>
                                      <a href={"https://opensea.io/assets/0xff44469c29ccf3be8c6051e7e1f75252148abf45/"+nft.id+""} target='_blank' className={nft.sold === 0 ? "btn btn-sm rounded-pill btn-blue text-white mt-1 disabled d-none" : "btn btn-sm rounded-pill btn-blue text-white mt-1"}>  {t('SHOP_NFT_OPENSEA')}</a>
                                    </div>
                                </figure>
                            </div>
                        </div>
                          )) 
                        }   
                    </div> 
                    <br/>
                    <div className="row">
                      <div className="col text-center">               
                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        pageCount={totalPages}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        disabledClassName={"navigationDisabled"}
                        activeClassName={"page-item active"}
                        activeLinkClassName={"page-link"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        />
                    </div>
                    </div> 
                  </div>
                </div>
              </div>
          </section>
          </div>
        
          <div className="position-fixed bottom-0 end-0 p-2 level-1">
            <button className="btn btn-white btn-icon d-lg-none rounded-pill shadow" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasFilter" aria-controls="offcanvasFilter">
              <i className="bi bi-justify-right fs-4"></i>
            </button>
          </div>
        
          <Footer/>
          </>
        )
    }
export default Shop